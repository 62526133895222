/* 
	Table of Contents

    01. Settings ----------------------------------
    02. Sprite ------------------------------------
    03. Generic -----------------------------------
    04. Base --------------------------------------
         Tablet Portrait --------------------------
    05. Fonts -------------------------------------
    06. Helpers -----------------------------------
         Clear ------------------------------------
         Notext -----------------------------------
         Hidden -----------------------------------
         Alignleft --------------------------------
         Alignright -------------------------------
         Disabled ---------------------------------
         Grid -------------------------------------
         Tablet Portrait --------------------------
         Responsive Helpers -----------------------
    07. Reset -------------------------------------
    08. Regions -----------------------------------
    09. Container ---------------------------------
         Tablet Portrait --------------------------
    10. Content -----------------------------------
         Tablet Portrait --------------------------
    11. Footer ------------------------------------
         Footer Nav -------------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    12. Header ------------------------------------
         Header Bar -------------------------------
         Header Content ---------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    13. Main --------------------------------------
    14. Shell -------------------------------------
    15. Sidebar -----------------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    16. Wrapper -----------------------------------
    17. Modules -----------------------------------
    18. About -------------------------------------
         Tablet Portrait --------------------------
    19. Box ---------------------------------------
    20. Box Accordion -----------------------------
         Box Accordion Orange ---------------------
         Tablet Portrait --------------------------
    21. Box Border --------------------------------
         Tablet Portrait --------------------------
    22. Breadcrumbs -------------------------------
         Tablet Portrait --------------------------
    23. Button ------------------------------------
         Button Large -----------------------------
         Button Dark ------------------------------
         Button White -----------------------------
         Button Default ---------------------------
         Button Dosis -----------------------------
         Button Block -----------------------------
         Button Small -----------------------------
         Tablet Portrait --------------------------
    24. Button Scroll Top -------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    25. Cart Item ---------------------------------
         Cart Item Simple -------------------------
         Cart Item Small --------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
         Cart Item Simple -------------------------
         Cart Item Small --------------------------
    26. Entry -------------------------------------
         Tablet Portrait --------------------------
    27. Entry Box ---------------------------------
         Entry Box Secondary ----------------------
         Tablet Portrait --------------------------
    28. Features Simple ---------------------------
         Tablet Portrait --------------------------
    29. Feature Simple ----------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    30. Form Elements -----------------------------
    31. Field -------------------------------------
         Field Textarea ---------------------------
         Tablet Portrait --------------------------
    32. Field Number ------------------------------
         Tablet Portrait --------------------------
    33. Range -------------------------------------
         Range Track ------------------------------
         Range Thumb ------------------------------
         Range Text -------------------------------
    34. Select ------------------------------------
         Select Small -----------------------------
         Select Inline ----------------------------
         Select Italic ----------------------------
         Tablet Portrait --------------------------
    35. Checkbox ----------------------------------
         Checkbox Small ---------------------------
         Tablet Portrait --------------------------
    36. Form --------------------------------------
         Form Light -------------------------------
         Form Coordinate --------------------------
         Tablet Portrait --------------------------
    37. Form Delivery -----------------------------
         Tablet Portrait --------------------------
    38. Icon --------------------------------------
         Tablet Portrait --------------------------
    39. List --------------------------------------
    40. List Carets -------------------------------
    41. List Icons --------------------------------
    42. List Features -----------------------------
         Tablet Portrait --------------------------
    43. List Actions ------------------------------
    44. List Images -------------------------------
         Tablet Portrait --------------------------
    45. List Dots ---------------------------------
         Tablet Portrait --------------------------
    46. Logo --------------------------------------
         Tablet Portrait --------------------------
    47. Nav Trigger -------------------------------
    48. Nav ---------------------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    49. Nav Simple --------------------------------
         Small Desktop ----------------------------
    50. Nav Utilities -----------------------------
         Small Desktop ----------------------------
    51. Nav Mobile --------------------------------
    52. Orders ------------------------------------
         Tablet Portrait --------------------------
    53. Order -------------------------------------
         Order Active -----------------------------
         Tablet Portrait --------------------------
    54. Paging ------------------------------------
         Tablet Portrait --------------------------
    55. Popup -------------------------------------
         Tablet Portrait --------------------------
    56. Price -------------------------------------
         Small Desktop ----------------------------
    57. Product Single ----------------------------
         Product Single Images --------------------
         Product Single Content -------------------
         Product Single Tag -----------------------
         Product Single Price ---------------------
         Tablet Portrait --------------------------
    58. Products Boxes ----------------------------
         Tablet Portrait --------------------------
    59. Product Box -------------------------------
         Product Box Button -----------------------
         Product Box Price ------------------------
         Product Box Tag --------------------------
         Product Box Small ------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    60. Product Simple ----------------------------
         Tablet Portrait --------------------------
    61. Promo -------------------------------------
         Tablet Portrait --------------------------
    62. Rating ------------------------------------
         Tablet Portrait --------------------------
    63. Rating Slider -----------------------------
         Rating Slider Green ----------------------
    64. Search ------------------------------------
    65. Section -----------------------------------
         Section Gutter Top -----------------------
         Section Gutter Bottom --------------------
         Section Table ----------------------------
         Section Contact --------------------------
         Section Coordinates ----------------------
         Section About ----------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    66. Section Tabs ------------------------------
         Tablet Portrait --------------------------
    67. Section Confirm ---------------------------
         Tablet Portrait --------------------------
    68. Slider ------------------------------------
         Slider Slides ----------------------------
         Slider Arrows ----------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    69. Slider Images -----------------------------
         Slider Images Dots -----------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    70. Socials -----------------------------------
         Socials Small ----------------------------
         Tablet Portrait --------------------------
    71. Sort --------------------------------------
         Tablet Portrait --------------------------
    72. Steps -------------------------------------
         Steps Head -------------------------------
         Steps Body -------------------------------
         Steps Nav --------------------------------
         Steps Gutters Small ----------------------
         Tablet Portrait --------------------------
    73. Step --------------------------------------
         Tablet Portrait --------------------------
    74. Subscribe ---------------------------------
         Subscribe Form ---------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    75. Tabs --------------------------------------
         Tabs Nav ---------------------------------
         Tablet Portrait --------------------------
    76. Tab ---------------------------------------
         Tablet Portrait --------------------------
    77. Tables ------------------------------------
         Tables Actions ---------------------------
         Tables Gutter None -----------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    78. Table Cart --------------------------------
         Table Head -------------------------------
         Table Cart Simple ------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
         Table Cart Simple ------------------------
    79. Table Gift --------------------------------
         Table Gift Foot --------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
    80. Table Order -------------------------------
         Tablet Portrait --------------------------
    81. Tags --------------------------------------
    82. Tag ---------------------------------------
         Tag Hover --------------------------------
    83. Testimonials ------------------------------
         Tablet Portrait --------------------------
    84. Testimonial -------------------------------
         Tablet Portrait --------------------------
    85. Widgets -----------------------------------
    86. Widget ------------------------------------
    87. Themes ------------------------------------
 */

/* ------------------------------------------------------------ *\
	Settings
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Sprite
\* ------------------------------------------------------------ */

.ico-blobs { background-image     : url(/medias/sprite.png); background-position     : 48.322147651006716% 90.35714285714286%; background-size     : 1962.5% 1850%; width     : 16px; height     : 16px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-cards-small { background-image     : url(/medias/sprite.png); background-position     : 17.725752508361204% 98.22064056939502%; background-size     : 2093.3333333333335% 1973.3333333333335%; width     : 15px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-cards { background-image     : url(/medias/sprite.png); background-position     : 41.72932330827068% 46.37096774193548%; background-size     : 654.1666666666667% 616.6666666666667%; width     : 48px; height     : 48px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-caret-down-small { background-image     : url(/medias/sprite.png); background-position     : 99.33993399339934% 62.84722222222222%; background-size     : 2854.5454545454545% 3700%; width     : 11px; height     : 8px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-caret-left-grey-hover { background-image     : url(/medias/sprite.png); background-position     : 5.647840531561462% 100%; background-size     : 2415.3846153846152% 1480%; width     : 13px; height     : 20px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-caret-left-grey { background-image     : url(/medias/sprite.png); background-position     : 0% 100%; background-size     : 2415.3846153846152% 1480%; width     : 13px; height     : 20px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-caret-left-small { background-image     : url(/medias/sprite.png); background-position     : 97.72727272727273% 79.86111111111111%; background-size     : 5233.333333333334% 3700%; width     : 6px; height     : 8px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-caret-left { background-image     : url(/medias/sprite.png); background-position     : 66.30824372759857% 45.41832669322709%; background-size     : 897.1428571428571% 657.7777777777777%; width     : 35px; height     : 45px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-caret-right-dark { background-image     : url(/medias/sprite.png); background-position     : 99.33993399339934% 38.43416370106761%; background-size     : 2854.5454545454545% 1973.3333333333335%; width     : 11px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-caret-right-small { background-image     : url(/medias/sprite.png); background-position     : 97.72727272727273% 75.69444444444444%; background-size     : 5233.333333333334% 3700%; width     : 6px; height     : 8px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-caret-right-white { background-image     : url(/medias/sprite.png); background-position     : 98.68852459016394% 44.71830985915493%; background-size     : 3488.8888888888887% 2466.666666666667%; width     : 9px; height     : 12px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-caret-right { background-image     : url(/medias/sprite.png); background-position     : 19.71326164874552% 68.92430278884463%; background-size     : 897.1428571428571% 657.7777777777777%; width     : 35px; height     : 45px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-caret-up-small { background-image     : url(/medias/sprite.png); background-position     : 99.33993399339934% 58.68055555555556%; background-size     : 2854.5454545454545% 3700%; width     : 11px; height     : 8px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-caret-up { background-image     : url(/medias/sprite.png); background-position     : 18.88111888111888% 80.72727272727272%; background-size     : 1121.4285714285713% 1409.5238095238094%; width     : 28px; height     : 21px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-cart-blue { background-image     : url(/medias/sprite.png); background-position     : 56.4625850340136% 79.00355871886121%; background-size     : 1570% 1973.3333333333335%; width     : 20px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-cart-btn { background-image     : url(/medias/sprite.png); background-position     : 64.40677966101694% 79.00355871886121%; background-size     : 1652.6315789473686% 1973.3333333333335%; width     : 19px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-cart-large-hover { background-image     : url(/medias/sprite.png); background-position     : 83.92857142857143% 55.39033457249071%; background-size     : 923.5294117647059% 1096.2962962962963%; width     : 34px; height     : 27px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-cart-large { background-image     : url(/medias/sprite.png); background-position     : 83.92857142857143% 43.866171003717476%; background-size     : 923.5294117647059% 1096.2962962962963%; width     : 34px; height     : 27px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-cart { background-image     : url(/medias/sprite.png); background-position     : 61.18881118881119% 62.909090909090914%; background-size     : 1121.4285714285713% 1409.5238095238094%; width     : 28px; height     : 21px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-category { background-image     : url(/medias/sprite.png); background-position     : 55.067567567567565% 41.36690647482014%; background-size     : 1744.4444444444443% 1644.4444444444443%; width     : 18px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-check-green { background-image     : url(/medias/sprite.png); background-position     : 24.161073825503358% 97.87234042553192%; background-size     : 1962.5% 2114.285714285714%; width     : 16px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-check { background-image     : url(/medias/sprite.png); background-position     : 29.757785467128027% 80.72727272727272%; background-size     : 1256% 1409.5238095238094%; width     : 25px; height     : 21px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-cowboy { background-image     : url(/medias/sprite.png); background-position     : 0% 47.5206611570248%; background-size     : 581.4814814814815% 548.1481481481482%; width     : 54px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-dice-small-white { background-image     : url(/medias/sprite.png); background-position     : 34.899328859060404% 90.35714285714286%; background-size     : 1962.5% 1850%; width     : 16px; height     : 16px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-dice-small { background-image     : url(/medias/sprite.png); background-position     : 41.61073825503356% 90.35714285714286%; background-size     : 1962.5% 1850%; width     : 16px; height     : 16px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-dice { background-image     : url(/medias/sprite.png); background-position     : 48.65900383141762% 23.868312757201647%; background-size     : 592.4528301886793% 558.4905660377358%; width     : 53px; height     : 53px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-face-smile { background-image     : url(/medias/sprite.png); background-position     : 28.187919463087248% 90.35714285714286%; background-size     : 1962.5% 1850%; width     : 16px; height     : 16px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-facebook-color { background-image     : url(/medias/sprite.png); background-position     : 93.81443298969072% 49.09090909090909%; background-size     : 1365.2173913043478% 1409.5238095238094%; width     : 23px; height     : 21px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-facebook { background-image     : url(/medias/sprite.png); background-position     : 24.124513618677042% 23.770491803278688%; background-size     : 550.8771929824561% 569.2307692307693%; width     : 57px; height     : 52px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-figure-small-white { background-image     : url(/medias/sprite.png); background-position     : 100% 0%; background-size     : 2415.3846153846152% 1741.1764705882351%; width     : 13px; height     : 17px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-figure-small { background-image     : url(/medias/sprite.png); background-position     : 100% 7.526881720430108%; background-size     : 2415.3846153846152% 1741.1764705882351%; width     : 13px; height     : 17px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-figure { background-image     : url(/medias/sprite.png); background-position     : 83.03886925795054% 24.793388429752067%; background-size     : 1012.9032258064516% 548.1481481481482%; width     : 31px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-gift-small { background-image     : url(/medias/sprite.png); background-position     : 50.51903114186851% 63.837638376383765%; background-size     : 1256% 1184%; width     : 25px; height     : 25px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-gift { background-image     : url(/medias/sprite.png); background-position     : 69.02985074626866% 0%; background-size     : 682.6086956521739% 569.2307692307693%; width     : 46px; height     : 52px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-goggles-small-white { background-image     : url(/medias/sprite.png); background-position     : 30.666666666666664% 97.87234042553192%; background-size     : 2242.8571428571427% 2114.285714285714%; width     : 14px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-goggles-small { background-image     : url(/medias/sprite.png); background-position     : 36.666666666666664% 97.87234042553192%; background-size     : 2242.8571428571427% 2114.285714285714%; width     : 14px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-goggles { background-image     : url(/medias/sprite.png); background-position     : 21.88679245283019% 46.558704453441294%; background-size     : 640.8163265306122% 604.0816326530612%; width     : 49px; height     : 49px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-happy-face { background-image     : url(/medias/sprite.png); background-position     : 48.84615384615385% 0%; background-size     : 581.4814814814815% 548.1481481481482%; width     : 54px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-heart-small { background-image     : url(/medias/sprite.png); background-position     : 99.33993399339934% 49.825783972125436%; background-size     : 2854.5454545454545% 3288.8888888888887%; width     : 11px; height     : 9px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-heart-white { background-image     : url(/medias/sprite.png); background-position     : 99.33993399339934% 54.35540069686411%; background-size     : 2854.5454545454545% 3288.8888888888887%; width     : 11px; height     : 9px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-heart { background-image     : url(/medias/sprite.png); background-position     : 90.60402684563759% 89.71631205673759%; background-size     : 1962.5% 2114.285714285714%; width     : 16px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-home { background-image     : url(/medias/sprite.png); background-position     : 81.8815331010453% 66.42066420664207%; background-size     : 1162.962962962963% 1184%; width     : 27px; height     : 25px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-horse-medium { background-image     : url(/medias/sprite.png); background-position     : 31.864406779661014% 66.03053435114504%; background-size     : 1652.6315789473686% 870.5882352941176%; width     : 19px; height     : 34px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-horse-small-white { background-image     : url(/medias/sprite.png); background-position     : 99.33993399339934% 23.021582733812952%; background-size     : 2854.5454545454545% 1644.4444444444443%; width     : 11px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-horse-small { background-image     : url(/medias/sprite.png); background-position     : 99.33993399339934% 30.935251798561154%; background-size     : 2854.5454545454545% 1644.4444444444443%; width     : 11px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-horse { background-image     : url(/medias/sprite.png); background-position     : 83.92857142857143% 0%; background-size     : 923.5294117647059% 528.5714285714286%; width     : 34px; height     : 56px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-instagram { background-image     : url(/medias/sprite.png); background-position     : 0% 0%; background-size     : 523.3333333333334% 548.1481481481482%; width     : 60px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-lock-blue { background-image     : url(/medias/sprite.png); background-position     : 99.66887417218543% 15.107913669064748%; background-size     : 2616.666666666667% 1644.4444444444443%; width     : 12px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-lock { background-image     : url(/medias/sprite.png); background-position     : 92.22972972972973% 59.25925925925925%; background-size     : 1744.4444444444443% 1138.4615384615386%; width     : 18px; height     : 26px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-magnifier-blue { background-image     : url(/medias/sprite.png); background-position     : 93.81443298969072% 20.51282051282051%; background-size     : 1365.2173913043478% 1286.9565217391305%; width     : 23px; height     : 23px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-magnifier { background-image     : url(/medias/sprite.png); background-position     : 40.484429065743946% 63.837638376383765%; background-size     : 1256% 1184%; width     : 25px; height     : 25px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-mail-blue { background-image     : url(/medias/sprite.png); background-position     : 77.02702702702703% 89.71631205673759%; background-size     : 1744.4444444444443% 2114.285714285714%; width     : 18px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-mail-grey { background-image     : url(/medias/sprite.png); background-position     : 83.89261744966443% 89.71631205673759%; background-size     : 1962.5% 2114.285714285714%; width     : 16px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-mail { background-image     : url(/medias/sprite.png); background-position     : 69.5945945945946% 89.71631205673759%; background-size     : 1744.4444444444443% 2114.285714285714%; width     : 18px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-masks-small-white { background-image     : url(/medias/sprite.png); background-position     : 55.218855218855225% 90.0355871886121%; background-size     : 1847.0588235294117% 1973.3333333333335%; width     : 17px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-masks-small { background-image     : url(/medias/sprite.png); background-position     : 62.2895622895623% 90.0355871886121%; background-size     : 1847.0588235294117% 1973.3333333333335%; width     : 17px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-masks { background-image     : url(/medias/sprite.png); background-position     : 0% 68.92430278884463%; background-size     : 615.686274509804% 657.7777777777777%; width     : 51px; height     : 45px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-minus { background-image     : url(/medias/sprite.png); background-position     : 73.95833333333334% 77.62237762237763%; background-size     : 1207.6923076923076% 2960%; width     : 26px; height     : 10px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-money { background-image     : url(/medias/sprite.png); background-position     : 94.13793103448276% 10.294117647058822%; background-size     : 1308.3333333333335% 1233.3333333333335%; width     : 24px; height     : 24px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-nav-trigger { background-image     : url(/medias/sprite.png); background-position     : 39.792387543252595% 80.14440433212997%; background-size     : 1256% 1557.8947368421052%; width     : 25px; height     : 19px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-pen-blue { background-image     : url(/medias/sprite.png); background-position     : 48.64864864864865% 79.85611510791367%; background-size     : 1744.4444444444443% 1644.4444444444443%; width     : 18px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-pen { background-image     : url(/medias/sprite.png); background-position     : 70.16949152542374% 62.454873646209386%; background-size     : 1652.6315789473686% 1557.8947368421052%; width     : 19px; height     : 19px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-plus { background-image     : url(/medias/sprite.png); background-position     : 94.13793103448276% 0%; background-size     : 1308.3333333333335% 1233.3333333333335%; width     : 24px; height     : 24px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-print { background-image     : url(/medias/sprite.png); background-position     : 92.54237288135593% 77.6173285198556%; background-size     : 1652.6315789473686% 1557.8947368421052%; width     : 19px; height     : 19px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-rocket { background-image     : url(/medias/sprite.png); background-position     : 67.02898550724638% 23.140495867768596%; background-size     : 826.3157894736843% 548.1481481481482%; width     : 38px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-search-blue { background-image     : url(/medias/sprite.png); background-position     : 21.21212121212121% 90.35714285714286%; background-size     : 1847.0588235294117% 1850%; width     : 17px; height     : 16px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-search-btn { background-image     : url(/medias/sprite.png); background-position     : 11.37123745819398% 98.22064056939502%; background-size     : 2093.3333333333335% 1973.3333333333335%; width     : 15px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-search { background-image     : url(/medias/sprite.png); background-position     : 55.067567567567565% 49.280575539568346%; background-size     : 1744.4444444444443% 1644.4444444444443%; width     : 18px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-select { background-image     : url(/medias/sprite.png); background-position     : 97.72727272727273% 67.48251748251748%; background-size     : 5233.333333333334% 2960%; width     : 6px; height     : 10px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-star { background-image     : url(/medias/sprite.png); background-position     : 98.0456026058632% 71.62629757785467%; background-size     : 4485.714285714285% 4228.571428571428%; width     : 7px; height     : 7px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-sun-white { background-image     : url(/medias/sprite.png); background-position     : 7.07070707070707% 90.68100358422939%; background-size     : 1847.0588235294117% 1741.1764705882351%; width     : 17px; height     : 17px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-sun { background-image     : url(/medias/sprite.png); background-position     : 14.14141414141414% 90.68100358422939%; background-size     : 1847.0588235294117% 1741.1764705882351%; width     : 17px; height     : 17px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-team-blue { background-image     : url(/medias/sprite.png); background-position     : 93.81443298969072% 30.4029304029304%; background-size     : 1365.2173913043478% 1286.9565217391305%; width     : 23px; height     : 23px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-team { background-image     : url(/medias/sprite.png); background-position     : 93.1740614334471% 69.0909090909091%; background-size     : 1495.2380952380952% 1409.5238095238094%; width     : 21px; height     : 21px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-timer-blue { background-image     : url(/medias/sprite.png); background-position     : 0% 82.5278810408922%; background-size     : 1365.2173913043478% 1096.2962962962963%; width     : 23px; height     : 27px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-timer { background-image     : url(/medias/sprite.png); background-position     : 9.278350515463918% 82.22222222222221%; background-size     : 1365.2173913043478% 1138.4615384615386%; width     : 23px; height     : 26px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-trash { background-image     : url(/medias/sprite.png); background-position     : 0% 91.33574007220217%; background-size     : 1847.0588235294117% 1557.8947368421052%; width     : 17px; height     : 19px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-twitter-color { background-image     : url(/medias/sprite.png); background-position     : 93.81443298969072% 40%; background-size     : 1365.2173913043478% 1409.5238095238094%; width     : 23px; height     : 21px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-twitter { background-image     : url(/medias/sprite.png); background-position     : 0% 23.868312757201647%; background-size     : 541.3793103448276% 558.4905660377358%; width     : 58px; height     : 53px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

.ico-youtube { background-image     : url(/medias/sprite.png); background-position     : 25.098039215686274% 0%; background-size     : 532.2033898305085% 548.1481481481482%; width     : 59px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }

@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 192dpi) {
	.ico-blobs { background-image     : url(/medias/sprite.@2x.png); background-position     : 7.046979865771812% 91.33574007220217%; background-size     : 1962.5% 1831.25%; width     : 16px; height     : 16px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-cards-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 17.725752508361204% 98.20143884892086%; background-size     : 2093.3333333333335% 1953.3333333333335%; width     : 15px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-cards { background-image     : url(/medias/sprite.@2x.png); background-position     : 41.72932330827068% 46.93877551020408%; background-size     : 654.1666666666667% 610.4166666666667%; width     : 48px; height     : 48px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-caret-down-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 99.17627677100495% 63.50877192982456%; background-size     : 2990.4761904761904% 3662.5%; width     : 10.5px; height     : 8px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-caret-left-grey-hover { background-image     : url(/medias/sprite.@2x.png); background-position     : 5.647840531561462% 100%; background-size     : 2415.3846153846152% 1465%; width     : 13px; height     : 20px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-caret-left-grey { background-image     : url(/medias/sprite.@2x.png); background-position     : 0% 100%; background-size     : 2415.3846153846152% 1465%; width     : 13px; height     : 20px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-caret-left-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 97.72727272727273% 80.7017543859649%; background-size     : 5233.333333333334% 3662.5%; width     : 6px; height     : 8px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-caret-left { background-image     : url(/medias/sprite.@2x.png); background-position     : 19.71326164874552% 69.75806451612904%; background-size     : 897.1428571428571% 651.1111111111111%; width     : 35px; height     : 45px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-caret-right-dark { background-image     : url(/medias/sprite.@2x.png); background-position     : 99.33993399339934% 38.84892086330935%; background-size     : 2854.5454545454545% 1953.3333333333335%; width     : 11px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-caret-right-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 97.72727272727273% 76.49122807017544%; background-size     : 5233.333333333334% 3662.5%; width     : 6px; height     : 8px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-caret-right-white { background-image     : url(/medias/sprite.@2x.png); background-position     : 98.68852459016394% 45.195729537366546%; background-size     : 3488.8888888888887% 2441.666666666667%; width     : 9px; height     : 12px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-caret-right { background-image     : url(/medias/sprite.@2x.png); background-position     : 66.30824372759857% 45.96774193548387%; background-size     : 897.1428571428571% 651.1111111111111%; width     : 35px; height     : 45px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-caret-up-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 99.17627677100495% 59.29824561403508%; background-size     : 2990.4761904761904% 3662.5%; width     : 10.5px; height     : 8px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-caret-up { background-image     : url(/medias/sprite.@2x.png); background-position     : 18.88111888111888% 81.61764705882352%; background-size     : 1121.4285714285713% 1395.2380952380952%; width     : 28px; height     : 21px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-cart-blue { background-image     : url(/medias/sprite.@2x.png); background-position     : 92.85714285714286% 83.81294964028777%; background-size     : 1570% 1953.3333333333335%; width     : 20px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-cart-btn { background-image     : url(/medias/sprite.@2x.png); background-position     : 60.33898305084746% 79.85611510791367%; background-size     : 1652.6315789473686% 1953.3333333333335%; width     : 19px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-cart-large-hover { background-image     : url(/medias/sprite.@2x.png); background-position     : 83.92857142857143% 56.015037593984964%; background-size     : 923.5294117647059% 1085.1851851851852%; width     : 34px; height     : 27px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-cart-large { background-image     : url(/medias/sprite.@2x.png); background-position     : 83.92857142857143% 44.3609022556391%; background-size     : 923.5294117647059% 1085.1851851851852%; width     : 34px; height     : 27px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-cart { background-image     : url(/medias/sprite.@2x.png); background-position     : 61.18881118881119% 63.60294117647059%; background-size     : 1121.4285714285713% 1395.2380952380952%; width     : 28px; height     : 21px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-category { background-image     : url(/medias/sprite.@2x.png); background-position     : 55.067567567567565% 41.81818181818181%; background-size     : 1744.4444444444443% 1627.7777777777778%; width     : 18px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-cowboy { background-image     : url(/medias/sprite.@2x.png); background-position     : 0% 48.11715481171548%; background-size     : 581.4814814814815% 542.5925925925926%; width     : 54px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-dice-small-white { background-image     : url(/medias/sprite.@2x.png); background-position     : 20.469798657718123% 91.33574007220217%; background-size     : 1962.5% 1831.25%; width     : 16px; height     : 16px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-dice-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 27.181208053691275% 91.33574007220217%; background-size     : 1962.5% 1831.25%; width     : 16px; height     : 16px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-dice { background-image     : url(/medias/sprite.@2x.png); background-position     : 48.65900383141762% 24.166666666666668%; background-size     : 592.4528301886793% 552.8301886792452%; width     : 53px; height     : 53px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-face-smile { background-image     : url(/medias/sprite.@2x.png); background-position     : 13.758389261744966% 91.33574007220217%; background-size     : 1962.5% 1831.25%; width     : 16px; height     : 16px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-facebook-color { background-image     : url(/medias/sprite.@2x.png); background-position     : 93.81443298969072% 39.338235294117645%; background-size     : 1365.2173913043478% 1395.2380952380952%; width     : 23px; height     : 21px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-facebook { background-image     : url(/medias/sprite.@2x.png); background-position     : 24.124513618677042% 24.066390041493776%; background-size     : 550.8771929824561% 563.4615384615385%; width     : 57px; height     : 52px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-figure-small-white { background-image     : url(/medias/sprite.@2x.png); background-position     : 100% 7.608695652173914%; background-size     : 2415.3846153846152% 1723.5294117647059%; width     : 13px; height     : 17px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-figure-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 100% 0%; background-size     : 2415.3846153846152% 1723.5294117647059%; width     : 13px; height     : 17px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-figure { background-image     : url(/medias/sprite.@2x.png); background-position     : 83.03886925795054% 25.10460251046025%; background-size     : 1012.9032258064516% 542.5925925925926%; width     : 31px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-gift-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 50.51903114186851% 64.55223880597015%; background-size     : 1256% 1172%; width     : 25px; height     : 25px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-gift { background-image     : url(/medias/sprite.@2x.png); background-position     : 69.02985074626866% 0%; background-size     : 682.6086956521739% 563.4615384615385%; width     : 46px; height     : 52px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-goggles-small-white { background-image     : url(/medias/sprite.@2x.png); background-position     : 60.333333333333336% 90.68100358422939%; background-size     : 2242.8571428571427% 2092.8571428571427%; width     : 14px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-goggles-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 54.333333333333336% 90.68100358422939%; background-size     : 2242.8571428571427% 2092.8571428571427%; width     : 14px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-goggles { background-image     : url(/medias/sprite.@2x.png); background-position     : 21.88679245283019% 47.13114754098361%; background-size     : 640.8163265306122% 597.9591836734694%; width     : 49px; height     : 49px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-happy-face { background-image     : url(/medias/sprite.@2x.png); background-position     : 48.84615384615385% 0%; background-size     : 581.4814814814815% 542.5925925925926%; width     : 54px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-hear-whitet { background-image     : url(/medias/sprite.@2x.png); background-position     : 99.33993399339934% 50.352112676056336%; background-size     : 2854.5454545454545% 3255.5555555555557%; width     : 11px; height     : 9px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-heart-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 99.33993399339934% 54.929577464788736%; background-size     : 2854.5454545454545% 3255.5555555555557%; width     : 11px; height     : 9px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-heart { background-image     : url(/medias/sprite.@2x.png); background-position     : 41.2751677852349% 90.68100358422939%; background-size     : 1962.5% 2092.8571428571427%; width     : 16px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-home { background-image     : url(/medias/sprite.@2x.png); background-position     : 81.8815331010453% 67.16417910447761%; background-size     : 1162.962962962963% 1172%; width     : 27px; height     : 25px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-horse-medium { background-image     : url(/medias/sprite.@2x.png); background-position     : 31.864406779661014% 66.79536679536679%; background-size     : 1652.6315789473686% 861.7647058823529%; width     : 19px; height     : 34px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-horse-small-white { background-image     : url(/medias/sprite.@2x.png); background-position     : 99.33993399339934% 31.272727272727273%; background-size     : 2854.5454545454545% 1627.7777777777778%; width     : 11px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-horse-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 99.33993399339934% 23.272727272727273%; background-size     : 2854.5454545454545% 1627.7777777777778%; width     : 11px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-horse { background-image     : url(/medias/sprite.@2x.png); background-position     : 83.92857142857143% 0%; background-size     : 923.5294117647059% 523.2142857142857%; width     : 34px; height     : 56px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-instagram { background-image     : url(/medias/sprite.@2x.png); background-position     : 0% 0%; background-size     : 523.3333333333334% 542.5925925925926%; width     : 60px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-lock-blue { background-image     : url(/medias/sprite.@2x.png); background-position     : 99.66887417218543% 15.272727272727273%; background-size     : 2616.666666666667% 1627.7777777777778%; width     : 12px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-lock { background-image     : url(/medias/sprite.@2x.png); background-position     : 92.22972972972973% 49.43820224719101%; background-size     : 1744.4444444444443% 1126.923076923077%; width     : 18px; height     : 26px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-magnifier-blue { background-image     : url(/medias/sprite.@2x.png); background-position     : 93.81443298969072% 10.37037037037037%; background-size     : 1365.2173913043478% 1273.913043478261%; width     : 23px; height     : 23px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-magnifier { background-image     : url(/medias/sprite.@2x.png); background-position     : 40.484429065743946% 64.55223880597015%; background-size     : 1256% 1172%; width     : 25px; height     : 25px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-mail-blue { background-image     : url(/medias/sprite.@2x.png); background-position     : 34.12162162162162% 90.68100358422939%; background-size     : 1744.4444444444443% 2092.8571428571427%; width     : 18px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-mail-grey { background-image     : url(/medias/sprite.@2x.png); background-position     : 47.98657718120805% 90.68100358422939%; background-size     : 1962.5% 2092.8571428571427%; width     : 16px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-mail { background-image     : url(/medias/sprite.@2x.png); background-position     : 82.0945945945946% 79.56989247311827%; background-size     : 1744.4444444444443% 2092.8571428571427%; width     : 18px; height     : 14px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-masks-small-white { background-image     : url(/medias/sprite.@2x.png); background-position     : 74.74747474747475% 79.85611510791367%; background-size     : 1847.0588235294117% 1953.3333333333335%; width     : 17px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-masks-small { background-image     : url(/medias/sprite.@2x.png); background-position     : 67.67676767676768% 79.85611510791367%; background-size     : 1847.0588235294117% 1953.3333333333335%; width     : 17px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-masks { background-image     : url(/medias/sprite.@2x.png); background-position     : 0% 69.75806451612904%; background-size     : 615.686274509804% 651.1111111111111%; width     : 51px; height     : 45px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-money { background-image     : url(/medias/sprite.@2x.png); background-position     : 94.13793103448276% 0%; background-size     : 1308.3333333333335% 1220.8333333333335%; width     : 24px; height     : 24px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-nav-trigger { background-image     : url(/medias/sprite.@2x.png); background-position     : 29.757785467128027% 81.02189781021897%; background-size     : 1256% 1542.1052631578948%; width     : 25px; height     : 19px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-pen-blue { background-image     : url(/medias/sprite.@2x.png); background-position     : 69.93243243243244% 62.909090909090914%; background-size     : 1744.4444444444443% 1627.7777777777778%; width     : 18px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-pen { background-image     : url(/medias/sprite.@2x.png); background-position     : 92.54237288135593% 76.64233576642336%; background-size     : 1652.6315789473686% 1542.1052631578948%; width     : 19px; height     : 19px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-print { background-image     : url(/medias/sprite.@2x.png); background-position     : 92.54237288135593% 68.24817518248175%; background-size     : 1652.6315789473686% 1542.1052631578948%; width     : 19px; height     : 19px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-rocket { background-image     : url(/medias/sprite.@2x.png); background-position     : 67.02898550724638% 23.430962343096233%; background-size     : 826.3157894736843% 542.5925925925926%; width     : 38px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-search-blue { background-image     : url(/medias/sprite.@2x.png); background-position     : 0% 91.33574007220217%; background-size     : 1847.0588235294117% 1831.25%; width     : 17px; height     : 16px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-search-btn { background-image     : url(/medias/sprite.@2x.png); background-position     : 11.37123745819398% 98.20143884892086%; background-size     : 2093.3333333333335% 1953.3333333333335%; width     : 15px; height     : 15px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-search { background-image     : url(/medias/sprite.@2x.png); background-position     : 55.067567567567565% 49.81818181818182%; background-size     : 1744.4444444444443% 1627.7777777777778%; width     : 18px; height     : 18px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-select { background-image     : url(/medias/sprite.@2x.png); background-position     : 97.72727272727273% 68.19787985865725%; background-size     : 5233.333333333334% 2930%; width     : 6px; height     : 10px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-star { background-image     : url(/medias/sprite.@2x.png); background-position     : 98.0456026058632% 72.37762237762237%; background-size     : 4485.714285714285% 4185.714285714285%; width     : 7px; height     : 7px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-sun-white { background-image     : url(/medias/sprite.@2x.png); background-position     : 45.79124579124579% 80.43478260869566%; background-size     : 1847.0588235294117% 1723.5294117647059%; width     : 17px; height     : 17px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-sun { background-image     : url(/medias/sprite.@2x.png); background-position     : 52.861952861952865% 80.43478260869566%; background-size     : 1847.0588235294117% 1723.5294117647059%; width     : 17px; height     : 17px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-team-blue { background-image     : url(/medias/sprite.@2x.png); background-position     : 93.81443298969072% 20.37037037037037%; background-size     : 1365.2173913043478% 1273.913043478261%; width     : 23px; height     : 23px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-team { background-image     : url(/medias/sprite.@2x.png); background-position     : 93.1740614334471% 59.55882352941176%; background-size     : 1495.2380952380952% 1395.2380952380952%; width     : 21px; height     : 21px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-timer-blue { background-image     : url(/medias/sprite.@2x.png); background-position     : 0% 83.45864661654136%; background-size     : 1365.2173913043478% 1085.1851851851852%; width     : 23px; height     : 27px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-timer { background-image     : url(/medias/sprite.@2x.png); background-position     : 9.278350515463918% 83.14606741573034%; background-size     : 1365.2173913043478% 1126.923076923077%; width     : 23px; height     : 26px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-trash { background-image     : url(/medias/sprite.@2x.png); background-position     : 38.72053872053872% 81.02189781021897%; background-size     : 1847.0588235294117% 1542.1052631578948%; width     : 17px; height     : 19px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-twitter-color { background-image     : url(/medias/sprite.@2x.png); background-position     : 93.81443298969072% 30.14705882352941%; background-size     : 1365.2173913043478% 1395.2380952380952%; width     : 23px; height     : 21px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-twitter { background-image     : url(/medias/sprite.@2x.png); background-position     : 0% 24.166666666666668%; background-size     : 541.3793103448276% 552.8301886792452%; width     : 58px; height     : 53px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
	.ico-youtube { background-image     : url(/medias/sprite.@2x.png); background-position     : 25.098039215686274% 0%; background-size     : 532.2033898305085% 542.5925925925926%; width     : 59px; height     : 54px; display     : inline-block; vertical-align     : middle; font-size     : 0px; }
}

/* ------------------------------------------------------------ *\
	Generic
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body { min-width: 320px; background: #fff; font-family: 'Dosis', sans-serif; font-size: 16px; line-height: 1.4; color: #2c414e; }

a { color: inherit; text-decoration: underline; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

a:hover,
a[href^="tel"] { text-decoration: none; }

h1,
h2,
h3,
h4,
h5,
h6 { margin-bottom: 0.7em; }

h1 { font-size: 50px; }

h2 { font-size: 40px; font-weight: 400; text-transform: uppercase; }

h3 { font-size: 30px; }

h4 { font-size: 25px; font-weight: 600; text-transform: uppercase; }

h5 { font-size: 22px; }

h6 { font-size: 16px; }

p,
ul,
ol,
dl,
hr,
table,
blockquote { margin-bottom: 1.4em; }

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child { margin-bottom: 0; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	h1 { font-size: 26px; }
	h2 { font-size: 20px; }
	h3 { font-size: 18px; }
	h4 { font-size: 16px; }
	h5 { font-size: 14px; }
	h6 { font-size: 12px; }
}

/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
	font-family: 'Dosis';
	src: url('/fonts/Dosis-ExtraLight.eot');
	src: url('/fonts/Dosis-ExtraLight.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Dosis-ExtraLight.woff') format('woff'),
		 url('/fonts/Dosis-ExtraLight.ttf') format('truetype'),
		 url('/fonts/Dosis-ExtraLight.svg') format('svg');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('/fonts/Dosis-Light.eot');
	src: url('/fonts/Dosis-Light.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Dosis-Light.woff') format('woff'),
		 url('/fonts/Dosis-Light.ttf') format('truetype'),
		 url('/fonts/Dosis-Light.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('/fonts/Dosis-Regular.eot');
	src: url('/fonts/Dosis-Regular.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Dosis-Regular.woff') format('woff'),
		 url('/fonts/Dosis-Regular.ttf') format('truetype'),
		 url('/fonts/Dosis-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('/fonts/Dosis-Medium.eot');
	src: url('/fonts/Dosis-Medium.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Dosis-Medium.woff') format('woff'),
		 url('/fonts/Dosis-Medium.ttf') format('truetype'),
		 url('/fonts/Dosis-Medium.svg') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('/fonts/Dosis-SemiBold.eot');
	src: url('/fonts/Dosis-SemiBold.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Dosis-SemiBold.woff') format('woff'),
		 url('/fonts/Dosis-SemiBold.ttf') format('truetype'),
		 url('/fonts/Dosis-SemiBold.svg') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('/fonts/Dosis-Bold.eot');
	src: url('/fonts/Dosis-Bold.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Dosis-Bold.woff') format('woff'),
		 url('/fonts/Dosis-Bold.ttf') format('truetype'),
		 url('/fonts/Dosis-Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('/fonts/Dosis-ExtraBold.eot');
	src: url('/fonts/Dosis-ExtraBold.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Dosis-ExtraBold.woff') format('woff'),
		 url('/fonts/Dosis-ExtraBold.ttf') format('truetype'),
		 url('/fonts/Dosis-ExtraBold.svg') format('svg');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Lato Hairline';
	src: url('/fonts/Lato-Hairline.eot');
	src: url('/fonts/Lato-Hairline.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Lato-Hairline.woff') format('woff'),
		 url('/fonts/Lato-Hairline.ttf') format('truetype'),
		 url('/fonts/Lato-Hairline.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Lato Hairline';
	src: url('/fonts/Lato-HairlineItalic.eot');
	src: url('/fonts/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Lato-HairlineItalic.woff') format('woff'),
		 url('/fonts/Lato-HairlineItalic.ttf') format('truetype'),
		 url('/fonts/Lato-HairlineItalic.svg') format('svg');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('/fonts/Lato-Light.eot');
	src: url('/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Lato-Light.woff') format('woff'),
		 url('/fonts/Lato-Light.ttf') format('truetype'),
		 url('/fonts/Lato-Light.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('/fonts/Lato-LightItalic.eot');
	src: url('/fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Lato-LightItalic.woff') format('woff'),
		 url('/fonts/Lato-LightItalic.ttf') format('truetype'),
		 url('/fonts/Lato-LightItalic.svg') format('svg');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('/fonts/Lato-Regular.eot');
	src: url('/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Lato-Regular.woff') format('woff'),
		 url('/fonts/Lato-Regular.ttf') format('truetype'),
		 url('/fonts/Lato-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('/fonts/Lato-Italic.eot');
	src: url('/fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Lato-Italic.woff') format('woff'),
		 url('/fonts/Lato-Italic.ttf') format('truetype'),
		 url('/fonts/Lato-Italic.svg') format('svg');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('/fonts/Lato-Bold.eot');
	src: url('/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Lato-Bold.woff') format('woff'),
		 url('/fonts/Lato-Bold.ttf') format('truetype'),
		 url('/fonts/Lato-Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('/fonts/Lato-BoldItalic.eot');
	src: url('/fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Lato-BoldItalic.woff') format('woff'),
		 url('/fonts/Lato-BoldItalic.ttf') format('truetype'),
		 url('/fonts/Lato-BoldItalic.svg') format('svg');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('/fonts/Lato-Black.eot');
	src: url('/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Lato-Black.woff') format('woff'),
		 url('/fonts/Lato-Black.ttf') format('truetype'),
		 url('/fonts/Lato-Black.svg') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('/fonts/Lato-BlackItalic.eot');
	src: url('/fonts/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Lato-BlackItalic.woff') format('woff'),
		 url('/fonts/Lato-BlackItalic.ttf') format('truetype'),
		 url('/fonts/Lato-BlackItalic.svg') format('svg');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/OpenSans-Light.eot');
	src: url('/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/OpenSans-Light.woff') format('woff'),
		 url('/fonts/OpenSans-Light.ttf') format('truetype'),
		 url('/fonts/OpenSans-Light.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/OpenSans-LightItalic.eot');
	src: url('/fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/OpenSans-LightItalic.woff') format('woff'),
		 url('/fonts/OpenSans-LightItalic.ttf') format('truetype'),
		 url('/fonts/OpenSans-LightItalic.svg') format('svg');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/OpenSans-Regular.eot');
	src: url('/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/OpenSans-Regular.woff') format('woff'),
		 url('/fonts/OpenSans-Regular.ttf') format('truetype'),
		 url('/fonts/OpenSans-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/OpenSans-Italic.eot');
	src: url('/fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/OpenSans-Italic.woff') format('woff'),
		 url('/fonts/OpenSans-Italic.ttf') format('truetype'),
		 url('/fonts/OpenSans-Italic.svg') format('svg');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/OpenSans-SemiBold.eot');
	src: url('/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/OpenSans-SemiBold.woff') format('woff'),
		 url('/fonts/OpenSans-SemiBold.ttf') format('truetype'),
		 url('/fonts/OpenSans-SemiBold.svg') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/OpenSans-SemiBoldItalic.eot');
	src: url('/fonts/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/OpenSans-SemiBoldItalic.woff') format('woff'),
		 url('/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype'),
		 url('/fonts/OpenSans-SemiBoldItalic.svg') format('svg');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/OpenSans-Bold.eot');
	src: url('/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/OpenSans-Bold.woff') format('woff'),
		 url('/fonts/OpenSans-Bold.ttf') format('truetype'),
		 url('/fonts/OpenSans-Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/OpenSans-BoldItalic.eot');
	src: url('/fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/OpenSans-BoldItalic.woff') format('woff'),
		 url('/fonts/OpenSans-BoldItalic.ttf') format('truetype'),
		 url('/fonts/OpenSans-BoldItalic.svg') format('svg');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/OpenSans-ExtraBold.eot');
	src: url('/fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/OpenSans-ExtraBold.woff') format('woff'),
		 url('/fonts/OpenSans-ExtraBold.ttf') format('truetype'),
		 url('/fonts/OpenSans-ExtraBold.svg') format('svg');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/OpenSans-ExtraBoldItalic.eot');
	src: url('/fonts/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/OpenSans-ExtraBoldItalic.woff') format('woff'),
		 url('/fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
		 url('/fonts/OpenSans-ExtraBoldItalic.svg') format('svg');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Luckiest Guy';
	src: url('/fonts/LuckiestGuy-Regular.eot');
	src: url('/fonts/LuckiestGuy-Regular.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/LuckiestGuy-Regular.woff') format('woff'),
		 url('/fonts/LuckiestGuy-Regular.ttf') format('truetype'),
		 url('/fonts/LuckiestGuy-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */

.clear:after { content     : ''; display     : block; clear     : both; }

/*  Notext  */

.notext { overflow     : hidden; text-indent     : 101%; white-space     : nowrap; }

/*  Hidden  */

[hidden],
.hidden { display: none !important; }

/*  Alignleft  */

.alignleft { float: left; }

/*  Alignright  */

.alignright { float: right; }

/*  Disabled  */

[disabled],
.disabled { cursor: default; }

/*  Grid  */

.cols { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; }

.col { max-width: 100%; -webkit-box-flex: 1; -ms-flex: 1 1; flex: 1 1; }

.col--1of2 { max-width: 50%; -webkit-box-flex: 0; -ms-flex: 0 0 50%; flex: 0 0 50%; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.col { -webkit-box-flex: 0; -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
}

/*  Responsive Helpers  */

.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block { display: none !important; }

@media (max-width: 1023px) {
	.hidden-sm { display: none !important; }
	.visible-sm-block { display: block !important; }
	.visible-sm-inline { display: inline !important; }
	.visible-sm-inline-block { display: inline-block !important; }
}

@media (min-width: 1024px) and (max-width: 1200px) {
	.hidden-md { display: none !important; }
	.visible-md-block { display: block !important; }
	.visible-md-inline { display: inline !important; }
	.visible-md-inline-block { display: inline-block !important; }
}

@media (min-width: 1201px) {
	.hidden-lg { display: none !important; }
	.visible-lg-block { display: block !important; }
	.visible-lg-inline { display: inline !important; }
	.visible-lg-inline-block { display: inline-block !important; }
}

/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */

* { padding: 0; margin: 0; outline: 0; -webkit-box-sizing: border-box; box-sizing: border-box; }

*:before,
*:after { -webkit-box-sizing: inherit; box-sizing: inherit; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main { display: block; }

template { display: none; }

html { -webkit-tap-highlight-color: rgba(0,0,0,0); tap-highlight-color: rgba(0,0,0,0); }

body { -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; -webkit-text-size-adjust: none; -moz-text-size-adjust: none; -ms-text-size-adjust: none; text-size-adjust: none; }

img,
iframe,
video,
audio,
object { max-width: 100%; }

img,
iframe { border: 0 none; }

img { height: auto; display: inline-block; vertical-align: middle; }

b,
strong { font-weight: bold; }

address { font-style: normal; }

svg:not(:root) { overflow: hidden; }

a,
button,
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="image"],
label[for] { cursor: pointer; }

a[href^="tel"],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] { cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner { padding: 0; border: 0; }

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
a[href^="tel"] { -webkit-appearance: none; -moz-appearance: none; appearance: none; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; appearance: none; }

textarea { overflow: auto; -webkit-overflow-scrolling: touch; overflow-scrolling: touch; }

button,
input,
optgroup,
select,
textarea { font-family: inherit; font-size: inherit; color: inherit; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; }

button,
select { text-transform: none; }

table { width: 100%; border-collapse: collapse; border-spacing: 0; }

nav ul,
nav ol { list-style: none outside none; }

/* ------------------------------------------------------------ *\
	Regions
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */

.container { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin-bottom: 20px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.container { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap; flex-flow: column wrap; margin-bottom: 43px; }
}

/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */

.content { width: 68.72%; }

.content .content__head { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin-bottom: 20px; }

.content .content__head-icon { position: relative; -ms-flex-negative: 0; flex-shrink: 0; width: 44px; height: 60px; padding-bottom: 12px; margin-right: 20px; background: #7eb620; line-height: 44px; text-align: center; }

.content .content__head-icon:before { content: ''; position: absolute; bottom: 0; left: 0; width: 0; height: 0; border-width: 0 22px 12px 22px; border-style: solid; border-color: transparent transparent #fff transparent; }

.content .content__title { font-size: 35px; font-weight: 400; line-height: 1; }

.content .content__title em { display: block; margin-top: 7px; font-family: 'Lato', sans-serif; font-size: 22px; font-weight: 300; letter-spacing: 1px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.content { width: 100%; }
	.content .content__head { margin-bottom: 10px; }
	.content .content__head-icon { width: 32px; height: 42px; margin-right: 8px; line-height: 32px; }
	.content .content__head-icon:before { border-width: 0 16px 8px 16px; }
	.content .content__head-icon i { width: 14px; height: 25px; }
	.content .content__title { margin-top: -3px; font-size: 20px; }
	.content .content__title em { margin-top: 1px; font-size: 10px; }
	
}

/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer { position: relative; padding: 67px 0 12px; background: #2c414e; color: #fff; }

.footer .footer__cols { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin-bottom: 47px; }

.footer .footer__title { margin-bottom: 10px; }

.footer .footer__bottom { text-align: center; }

.footer .footer__copyright { position: absolute; right: 20px; bottom: 12px; font-family: 'Lato', sans-serif; font-size: 15px; }

/*  Footer Nav  */

.footer .footer__nav { display: inline-block;  }

.footer .footer__nav ul { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin: 0; }

.footer .footer__nav li { margin: 0 20px; }

.footer .footer__nav a { text-decoration: none; }

.footer .logo { margin: 8px 24px 0 0; }

@media ( min-width: 1025px ){
	.footer .footer__nav a:hover { opacity: .7; }
}

/* Small Desktop */

@media (max-width: 1200px) {
	.footer { padding: 40px 0 10px; }
	
	.footer .footer__nav li { margin: 0 13px; }

	.footer .footer__copyright { right: 15px; font-size: 14px; }

	.footer .logo { margin: 0; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.footer { padding-bottom: 37px; }
	.footer .footer__cols { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap; flex-flow: column wrap; padding: 0 10px; margin-bottom: 16px; }
	.footer .footer__col { padding: 0 15px; margin-bottom: 23px; }
	.footer .footer__col:last-child { margin-bottom: 0; }
	
	.footer .footer__title { font-size: 25px; }
	
	.footer .footer__nav { font-size: 12px; }
	.footer .footer__nav li { margin: 0 5px; }
	
	.footer .footer__copyright { bottom: 20px; right: 0; width: 100%; font-size: 9px; text-align: center; }
}

/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header { position: relative; z-index: 20; }

/*  Header Bar  */

.header .header__bar { padding: 15px 0 18px; background: #2c414e; color: #fff; }

.header .header__bar-inner { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; padding-right: 30px; }

.header .header__bar-entry { margin-right: 15px; font-size: 17px; font-weight: 700; }

.header .nav-utilities { margin-left: 30px; }

/*  Header Content  */

.header .header__content { padding: 8px 0; background: #fff; }

.header .header__content-inner { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; }

.header .nav { padding-right: 11px; }

/* Small Desktop */

@media (max-width: 1200px) {
	.header .header__bar { font-size: 14px; }
	.header .header__bar-inner { padding-right: 0; }
	.header .header__bar-entry { margin-right: 0; font-size: 14px; }
	
	.header .nav-utilities { margin-left: 10px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.header .header__bar { padding: 2px 5px 7px; }
	.header .header__bar-entry { font-size: 13px; font-weight: 600; letter-spacing: 0.035em; }

	.header .header__content { padding: 6px 0; }
	.header .nav { position: absolute; top: 100%; left: 0; width: 100%; padding: 10px 20px; background: #fff; opacity: 0; visibility: hidden; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }
	.header .nav.active { opacity: 1; visibility: visible; }
}

/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell { max-width: 1155px; padding-left: 10px; padding-right: 10px; margin: auto; }

.shell--fluid { max-width: none; }

/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */

.sidebar { width: 26.1%; }

.sidebar .sidebar__inner { overflow: hidden; border: 5px solid #7eb620; border-radius: 8px; }

.sidebar .sidebar__head { padding: 6px 5px; background: #7eb620; color: #fff; text-align: center; }

.sidebar .sidebar__title { font-size: 20px; font-weight: 600; letter-spacing: 0.03em; }

.sidebar .sidebar__body { padding: 0 14px 12px 21px; }

/* Small Desktop */

@media (max-width: 1200px) {
	.sidebar { width: 30%; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.sidebar { display: none; }
}

/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper { min-height: 100vh; }

/* ------------------------------------------------------------ *\
	Modules
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	About
\* ------------------------------------------------------------ */

.about { margin-bottom: 114px; }

.about .about__inner { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; max-width: 1016px; margin: 0 auto; }

.about .about__content { width: 52%; }

.about .about__aside { width: 38.4%; padding: 27px 15px; border-radius: 10px; background: #2c414e; color: #fff; }

.about .about__title { margin-bottom: 5px; color: #dc5d45; text-transform: uppercase; }

.about .about__entry { font-size: 16px; letter-spacing: .046em; }

.about .about__entry p { margin-bottom: 8px; letter-spacing: .065em; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.about { margin-bottom: 65px; }
	.about .about__inner { display: block; padding: 0 7px; }
	.about .about__content { width: 100%; margin-bottom: 20px; }
	.about .about__aside { width: 100%; padding: 20px 15px; }
	
	.about .about__title { font-size: 20px; }
	.about .about__entry { font-size: 13px; line-height: 1.35; }
}

/* ------------------------------------------------------------ *\
	Box
\* ------------------------------------------------------------ */

.box { padding: 22px 26px 32px; border-radius: 9px; background: #51becc; color: #fff; }

/* ------------------------------------------------------------ *\
	Box Accordion
\* ------------------------------------------------------------ */

.box-accordion { position: relative; padding: 38px 20px 40px; border-radius: 40px; margin: 0 13px; background: #2c414e; color: #fff; text-align: center; }

.box-accordion .box__head { margin-bottom: 48px; }

.box-accordion .box__title { line-height: 1; text-transform: uppercase; }

.box-accordion .box__title small { display: block; font-family: 'Lato', sans-serif; font-size: 20px; font-weight: 400; font-style: italic; text-transform: none; }

.box-accordion .box__body { max-width: 262px; margin: 0 auto; }

/*  Box Accordion Orange  */

.box-accordion--orange { background: #dc5d45; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.box-accordion { height: auto; padding: 10px 8px; border-radius: 10px; margin: 0 5px 17px; }

	.box-accordion .box__head { position: relative; padding-right: 40px; margin-bottom: 0; text-align: left; }
	.box-accordion .box__head:after,
	.box-accordion .box__head:before { content: ''; position: absolute; top: 5px; background-position: 0 0; background-repeat: no-repeat; background-size: 100% 100%; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }
	
	.box-accordion .box__head:after { right: 7px; width: 26px; height: 10px; background-image     : url(/medias/sprite.png); background-position     : 73.95833333333334% 77.62237762237763%; background-size     : 1207.6923076923076% 2960%; opacity: 0; visibility: hidden; }
	.box-accordion .box__head:before { right: 12px; width: 24px; height: 24px; background-image     : url(/medias/sprite.png); background-position     : 94.13793103448276% 0%; background-size     : 1308.3333333333335% 1233.3333333333335%; }

	.box-accordion .box__title { font-size: 20px; }
	.box-accordion .box__title small { font-size: 14px; font-weight: 300; }
	
	.box-accordion .box__body { display: none; max-width: 220px; margin-top: 10px; }
	
	.box-accordion.active .box__body { display: block; }
	.box-accordion.active .box__head:after { opacity: 1; visibility: visible; }
	.box-accordion.active .box__head:before { opacity: 0; visibility: hidden; }
}

/* ------------------------------------------------------------ *\
	Box Border
\* ------------------------------------------------------------ */

.boxes-border .cols { margin: 0 -15px; }

.boxes-border .col { padding: 0 15px; }

.box-border { padding: 24px 30px 31px; border: 4px solid #dc5d45; border-radius: 40px; font-family: 'Lato', sans-serif; font-size: 20px; min-height: 350px; }

.box-border .box__title { margin-bottom: 37px; font-family: 'Dosis', sans-serif; font-weight: 600; color: #dc5d45; text-transform: uppercase; text-align: center; }

.box-border .box__entry { margin-bottom: 10px; line-height: 1.3; }

.box-border .box__actions { color: #9b9b9b; text-align: right; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.boxes-border { padding: 0 10px; }

	.box-border { padding: 17px 12px 5px; border-width: 3px; border-radius: 11px; margin-bottom: 16px; font-size: 13px; min-height: 220px; }
	.box-border .box__title { margin-bottom: 9px; font-size: 18px; }
	.box-border .box__entry { margin-bottom: 0; line-height: 1.5; }
	.box-border .box__actions { line-height: 1; }
}

/* ------------------------------------------------------------ *\
	Breadcrumbs
\* ------------------------------------------------------------ */

.breadcrumbs { padding: 20px 0; font-family: 'Lato', sans-serif; color: #9b9b9b; }

.breadcrumbs ul { font-size: 0; }

.breadcrumbs li { position: relative; display: inline-block; vertical-align: middle; font-size: 17px; }

.breadcrumbs li + li { padding-left: 13px; margin-left: 16px; }

.breadcrumbs li + li:before { content: '>'; position: absolute; top: 0px; left: 0; }

.breadcrumbs a { text-decoration: none; }

.breadcrumbs a:hover { color: #7eb620; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.breadcrumbs { padding: 4px 0; margin-bottom: 6px; }
	.breadcrumbs li { font-size: 10px; }
	.breadcrumbs li + li { padding-left: 8px; margin-left: 6px; }
	.breadcrumbs i { width: 13px; height: 13px; }
}

/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn { display: inline-block; vertical-align: middle; min-width: 164px; height: 42px; padding: 0 10px; border: 2px solid #dc5d45; border-radius: 8px; background: transparent; font-family: 'Lato', sans-serif; font-weight: 900; line-height: 38px; color: #dc5d45; text-align: center; text-decoration: none; text-transform: uppercase; cursor: pointer; -webkit-appearance: none; -moz-appearance: none; appearance: none; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.btn i { -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.btn i + i { opacity: 0; visibility: hidden; }

.btn--with-icon .btn__icon { position: relative; display: inline-block; vertical-align: middle; margin-right: 10px; font-size: 0; line-height: 1; }

.btn--with-icon i { -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.btn--with-icon i + i { position: absolute; top: 0; left: 0; opacity: 0; visibility: hidden; }

.btn--with-icon:hover i { opacity: 0; visibility: hidden; }

.btn--with-icon:hover i + i { opacity: 1; visibility: visible; }

.btn:hover { background: #dc5d45; color: #fff; }

.btn:hover i { opacity: 0; }

/*  Button Large  */

.btn--large { height: 48px; font-size: 20px; font-weight: 700; line-height: 44px; }

/*  Button Dark  */

.btn--dark { border-color: #2c414e; color: #2c414e; }

.btn--dark:hover { background: #2c414e; color: #fff; opacity: .7; }

/*  Button White  */

.btn--white { border-color: #fff; color: #fff; }

.btn--white:hover { background: #fff; color: #2c414e; }

/*  Button Default  */

.btn--default { border-color: #9b9b9b; color: #9b9b9b; }

.btn--default:hover { background: #9b9b9b; color: #fff; }

/*  Button Dosis  */

.btn--dosis { font-family: 'Dosis', sans-serif; }

/*  Button Block  */

.btn--block { display: block; padding-left: 0; padding-right: 0; }

/*  Button Small  */

.btn--small { min-width: 96px; font-weight: 700; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.btn--icon-mobile { min-width: auto; border: 0; background: transparent; font-size: 0; }
}

/* ------------------------------------------------------------ *\
	Button Scroll Top
\* ------------------------------------------------------------ */

.btn-scroll-top { position: absolute; top: 0; right: -31px; z-index: 1; -webkit-transform: translateY(-49%); -ms-transform: translateY(-49%); transform: translateY(-49%); display: inline-block; vertical-align: middle; width: 56px; height: 56px; border: 3px solid #fff; border-radius: 50%; background: #2c414e; font-size: 0; line-height: 0; -webkit-appearance: none; -moz-appearance: none; appearance: none; text-align: center; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.btn-scroll-top:hover { background: rgb(76, 94, 105); }

/* Small Desktop */

@media (max-width: 1200px) {
	.btn-scroll-top { right: 0; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.btn-scroll-top { -webkit-transform: translateY(-77%); -ms-transform: translateY(-77%); transform: translateY(-77%); width: 40px; height: 40px; }
	.btn-scroll-top i { width: 22px; height: 17px; }
}

/* ------------------------------------------------------------ *\
	Cart Item
\* ------------------------------------------------------------ */

.cart-item { position: relative; }

.cart-item .cart__container { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; }

.cart-item .cart__title { margin-bottom: 12px; font-weight: 600; }

.cart-item .cart__meta { display: block; font-size: 15px; font-weight: 500; color: #9b9b9b; }

.cart-item .cart__image { min-width: 130px; margin-right: 11px; text-align: center; }

.cart-item .cart__tag { display: inline-block; vertical-align: middle; padding: 3px 10px; border-radius: 2px; background: #b6d067; font-weight: 600; font-size: 17px; line-height: 1; color: #fff; text-transform: uppercase; text-align: center; }

.cart-item .checkbox { text-align: center; }

.cart-item .cart__actions-mobile { display: none; }

/*  Cart Item Simple  */

.cart-item--simple .cart__image { min-width: 160px; margin-right: 20px; }

.cart-item--simple .price { font-weight: 700; color: #dc5d45; text-align: right; }

.cart-item--simple .price .price__discount { margin: 0 0 8px; color: #2c414e; text-decoration: line-through; }

/*  Cart Item Small  */

.cart-item--small .cart__title { margin-bottom: 0; font-size: 20px; }

.cart-item--small .cart__image { min-width: 88px; }

.cart-item--small .cart__qty { display: block; text-align: center; }

.cart-item--small .cart__price-single { font-size: 13px; text-align: right; }

.cart-item--small .cart__price-total { font-size: 16px; font-weight: 600; text-align: right; }

/* Small Desktop */

@media (max-width: 1200px) {
	.cart-item .cart__title { font-size: 18px; }
	.cart-item .cart__meta { font-size: 12px; }
	.cart-item .cart__tag { font-size: 14px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.cart-item { padding-bottom: 15px; border-bottom: 1px solid #2c414e; margin-bottom: 15px; }
	.cart-item .cart__container { -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; padding-right: 17px; }
	
	.cart-item .cart__image { min-width: 0; max-width: 110px; }
	
	.cart-item .cart__title { padding-top: 23px; line-height: 1.25; text-align: right; }
	
	.cart-item .cart__meta { font-size: 11px; }
	.cart-item .cart__tag { position: absolute; top: -3px; right: 19px; padding: 4px 6px; font-size: 13px; }
	
	.cart-item .cart__price-single { display: block; text-align: right; }
	.cart-item .cart__price-total { position: absolute; right: 0; bottom: 60px; font-size: 24px; }
	
	.cart-item .cart__actions-mobile { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: flex-end; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; padding: 13px 0 0; }
	.cart-item .cart__actions-left { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; }
	.cart-item .cart__actions-entry { font-size: 13px; font-weight: 500; }
	.cart-item .cart__actions-entry i { vertical-align: text-top; width: 15px; height: 15px; margin-right: 5px; }
	
	.cart-item .checkbox { width: 16px; height: 16px; margin-right: 16px; font-size: 0; }

	.cart-item .field-number { margin-top: 8px; }
	
	/*  Cart Item Simple  */
	.cart-item--simple .cart__container { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; padding-right: 75px; }
	.cart-item--simple .cart__image { max-width: 76px; margin-right: 15px; }
	.cart-item--simple .cart__title { padding-top: 9px; font-size: 16px; text-align: left; }
	.cart-item--simple .cart__price-total { bottom: 50%; -webkit-transform: translateY(50%); -ms-transform: translateY(50%); transform: translateY(50%); }

	/*  Cart Item Small  */
	.cart-item--small { padding: 5px 0; }
	
	.cart-item--small .cart__container { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; padding-right: 75px; }
	
	.cart-item--small .cart__image { min-width: 0; max-width: 48px; }
	
	.cart-item--small .cart__title { padding-top: 13px; font-size: 11px; font-weight: 700; text-align: left; }
	
	.cart-item--small .cart__price-single { position: relative; font-size: 11px; text-align: left; }
	.cart-item--small .cart__price-inner { display: inline-block; vertical-align: top; margin-left: 4px; }
	.cart-item--small .cart__price-total { bottom: 4px; font-size: 16px; }
	
	.cart-item--small .price .price__label { display: inline-block; }
}

/* ------------------------------------------------------------ *\
	Entry
\* ------------------------------------------------------------ */

.entry { font-family: 'Lato', sans-serif; font-size: 13px; }

.entry .entry__title { margin-bottom: 7px; font-family: 'Dosis', sans-serif; color: #dc5d45; text-transform: uppercase; }

.entry .entry__text { margin-bottom: 36px; font-size: 16px; letter-spacing: .01em; }

.entry .entry__text p { margin-bottom: 8px; }

.entry .entry__text dd { margin-bottom: 7px; }

.entry .entry__foot { font-size: 16px; letter-spacing: .01em; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.entry { line-height: 1.35; }
	.entry p { margin-bottom: 8px; }
	
	.entry .entry__title { font-size: 20px; }
	.entry .entry__text { font-size: 13px; line-height: 1.4; }
	.entry .entry__text p { margin-bottom: 7px; }
	.entry .entry__foot { font-size: 13px; line-height: 1.4; }
}

/* ------------------------------------------------------------ *\
	Entry Box
\* ------------------------------------------------------------ */

.entry-box { padding: 29px 26px 10px; border: 4px solid #2c414e; border-radius: 8px; font-family: 'Lato', sans-serif; font-size: 19px; text-align: center; }

.entry-box h5 { margin-bottom: 2px; font-family: 'Dosis', sans-serif; line-height: 1.3; color: #dc5d45; text-transform: uppercase; }

.entry-box p { padding: 0 16px; margin-bottom: 35px; font-weight: 400; line-height: 1.2; }

.entry-box strong { display: inline-block; font-weight: 600; }

.entry-box em { display: block; margin-bottom: 10px; font-size: 18px; }

.entry-box .entry__title { padding-bottom: 4px; border-bottom: 1px solid #dc5d45; margin-bottom: 7px; }

.entry-box .entry__section { margin-bottom: 23px; }

.entry-box .entry__actions { margin-top: 42px; font-size: 14px; color: #9b9b9b; }

/*  Entry Box Secondary  */

.entry-box--secondary { padding: 16px 20px 13px; border-color: #dc5d45; font-size: 18px; text-align: left; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.entry-box { padding: 23px 33px 0; font-size: 13px; }

	.entry-box h5 { font-size: 20px; line-height: 1.1; }
	.entry-box em { margin-bottom: 10px; font-size: 13px; }
	.entry-box p { padding: 0 10px; line-height: 1.5; }
	
	.entry-box br { display: none; }
	
	.entry-box--secondary { padding: 16px 22px 16px; }
	.entry-box--secondary .entry__title { margin-bottom: 9px; font-size: 18px; }
	.entry-box--secondary .entry__section { margin-bottom: 15px; }
	.entry-box--secondary .entry__actions { margin-top: 10px; font-size: 11px; }
}

/* ------------------------------------------------------------ *\
	Features Simple
\* ------------------------------------------------------------ */

.features-simple { padding: 17px 0; background: #dc5d45; color: #fff; }

.features-simple .features__container { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin: 0 -5px; }

.features-simple--green { background: #7eb620; }

.features-simple--blue { background: #51becc; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.features-simple { padding: 5px 0; }
}

/* ------------------------------------------------------------ *\
	Feature Simple
\* ------------------------------------------------------------ */

.feature-simple { padding: 0 5px; font-family: 'Lato', sans-serif; font-size: 18px; line-height: 1.3; text-align: center; }

.feature-simple .feature__entry strong { display: block; font-family: 'Dosis', sans-serif; font-size: 20px; text-transform: uppercase; }

.feature-simple .feature__icon { display: inline-block; margin-bottom: 7px; }

/* Small Desktop */

@media (max-width: 1200px) {
	.feature-simple { width: 100%; font-size: 16px; }
	.feature-simple .feature__entry strong { font-size: 18px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.feature-simple { width: 50%; font-size: 0; }
	.feature-simple .feature__entry strong { font-size: 10px; }
	.feature-simple .feature__icon { width: 30px; height: 30px; }
	.feature-simple .feature__icon i { width: 100%; height: 100%; }
}

/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

input::-webkit-input-placeholder { color: inherit; opacity: 1; }

input:-ms-input-placeholder { color: inherit; opacity: 1; }

input::-ms-input-placeholder { color: inherit; opacity: 1; }

input::placeholder { color: inherit; opacity: 1; }

textarea::-webkit-input-placeholder { color: inherit; opacity: 1; }

textarea:-ms-input-placeholder { color: inherit; opacity: 1; }

textarea::-ms-input-placeholder { color: inherit; opacity: 1; }

textarea::placeholder { color: inherit; opacity: 1; }

input:-webkit-autofill { -webkit-text-fill-color: inherit; -webkit-box-shadow: 0 0 0 1000px #fff inset; }

select::-ms-expand { display: none; }

/* ------------------------------------------------------------ *\
	Field
\* ------------------------------------------------------------ */

.field { width: 100%; height: 48px; padding: 0 22px; border: 1px solid #979797; border-radius: 8px; background: #fff; font-family: 'Lato', sans-serif; font-size: 18px; font-weight: 300; font-style: italic; color: #253553; -webkit-appearance: none; -moz-appearance: none; appearance: none; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.field:focus { border-color: #dc5d45; }

/*  Field Textarea  */

.field--textarea { height: 124px; padding: 10px 22px; resize: none; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.field { height: 32px; padding: 0 10px; font-size: 13px; }
	.field--textarea { height: 80px; padding: 10px; }
}

/* ------------------------------------------------------------ *\
	Field Number
\* ------------------------------------------------------------ */

.field-number { position: relative; font-size: 0; line-height: 1; }

.field-number input { width: 43px; height: 38px; padding: 0 5px; border: 1px solid #979797; border-radius: 8px; background: transparent; font-family: 'Lato', sans-serif; font-size: 16px; font-weight: 400; color: #dc5d45; text-align: center; }

.field-number .field__actions { position: relative; display: inline-block; vertical-align: top; width: 10px; height: 38px; margin-left: 8px; line-height: 1; font-size: 0; }

.field-number .field__label { display: inline-block; margin-right: 7px; font-family: 'Lato', sans-serif; font-size: 16px; font-weight: 400; color: #253553; }

.field-number .field__increase,
.field-number .field__decrease { position: absolute; left: 0; }

.field-number .field__increase { bottom: 52%; border: none; background: none; }

.field-number .field__decrease { top: 52%; border: none; background: none; }

.field-number--small input { height: 30px; }

.field-number--small .field__actions { height: 30px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.field-number .field__label { margin-right: 15px; font-size: 13px; }

	.field-number .field__increase { bottom: 54%; }
	.field-number .field__decrease { top: 54%; }
}

/* ------------------------------------------------------------ *\
	Range
\* ------------------------------------------------------------ */

.range { position: relative; margin-bottom: 3px; }

.range .range__controls { margin-bottom: 10px; }

/*  Range Track  */

.range .range__track { position: relative; display: block; width: 100%; height: 3px; background: #d8d8d8; cursor: pointer; }

.range .range__track-inner { position: absolute; top: 0; left: 0; height: 100%; background: #7eb620; }

/*  Range Thumb  */

.range .range__thumb { position: absolute; top: -3px; width: 9px; height: 9px; background: #7eb620; }

.range .range__thumb--start { left: 0; }

.range .range__thumb--end { left: 20px; }

/*  Range Text  */

.range .range__text { display: block; padding: 0 6px; font-family: 'Lato', sans-serif; font-size: 12px; font-style: italic; }

.range input:focus { outline: none; }

.range input::-ms-track { width: 100%; background: transparent; border-color: transparent; color: transparent; cursor: pointer; }

/* ------------------------------------------------------------ *\
	Select
\* ------------------------------------------------------------ */

.select { position: relative; font-family: 'Lato', sans-serif; }

.select .select__controls { position: relative; }

.select .select__controls:before { content: ''; position: absolute; top: 50%; right: 10px; width: 6px; height: 10px; margin-top: -5px; background-image     : url(/medias/sprite.@2x.png); background-position     : 97.72727272727273% 68.19787985865725%; background-size     : 5233.333333333334% 2930%; }

.select select { width: 100%; height: 48px; padding: 0 20px 0 12px; border: 1px solid #979797; border-radius: 8px; font-family: 'Lato', sans-serif; color: #2c414e; -webkit-appearance: none; -moz-appearance: none; appearance: none; }

/*  Select Small  */

.select--small { font-size: 15px; }

.select--small select { height: 26px; padding: 0 20px 0 12px; font-size: 15px; }

/*  Select Inline  */

.select--inline .select__controls { display: inline-block; vertical-align: middle; margin: 0 6px 0 3px; }

.select--inline .select__controls:before { right: 6px; }

.select--inline select { padding: 0 15px 0 5px; border-color: #7eb620; }

/*  Select Italic  */

.select--italic select { font-style: italic; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.select .select__entry { display: inline-block; min-width: 44px; }
	.select select { height: 32px; }

	.select--small { font-size: 12px; }
	.select--small select { height: 26px; font-size: 12px; }
	
	.select--inline { margin-bottom: 11px; }
}

/* ------------------------------------------------------------ *\
	Checkbox
\* ------------------------------------------------------------ */

.checkbox input { display: none; }

.checkbox label { position: relative; display: inline-block; padding-left: 25px; }

.checkbox label:after { content: ''; position: absolute; top: 0; left: 0; width: 20px; height: 20px; border: 1px solid #979797; border-radius: 3px; background: #fff; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.checkbox label:before { content: ''; position: absolute; top: 1px; left: 6px; z-index: 1; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); width: 7px; height: 13px; border-width: 0 2px 2px 0; border-style: solid; border-color: #fff; opacity: 0; visibility: hidden; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.checkbox input:checked + label:after { border-color: #2c414e; background: #2c414e; }

.checkbox input:checked + label:before { opacity: 1; visibility: visible; }

.checkbox input:disabled + label:after { opacity: 0.3; }

/*  Checkbox Small  */

.checkbox--small label { font-family: 'Lato', sans-serif; font-size: 16px; line-height: 1; }

.checkbox--small label a { font-style: italic; }

.checkbox--small label:after { width: 14px; height: 14px; }

.checkbox--small label:before { top: 2px; left: 5px; width: 5px; height: 9px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.checkbox label { font-size: 13px; }

	.checkbox label:after { width: 16px; height: 16px; }
	.checkbox label:before { width: 5px; height: 11px; }
	
	.checkbox--radio label:after { width: 20px; height: 20px; }
	.checkbox--radio label:before { width: 7px; height: 13px; }
}

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form { color: #253553; }

.form .form__row {  margin-bottom: 25px; }

.form .form__row--gutter-none { margin: 0; }

.form .form__label { display: block; margin-bottom: 11px; font-size: 18px; font-weight: 600; color: inherit; text-transform: uppercase; }

.form .form__label span { color: #dc5d45; }

.box-accordion--orange .form .form__label span { color: white; }

.form .form__cols { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin: 0 -4px; }

.form .form__col { -webkit-box-flex: 1; -ms-flex: 1 1; flex: 1 1; max-width: 100%; padding: 0 4px; }

.form .form__col--size-1 { -webkit-box-flex: 0; -ms-flex: 0 0 148px; flex: 0 0 148px; max-width: 148px; }

.form .form__actions { margin-top: 56px; text-align: center; }

.form .form__controls--narrow { max-width: 320px; }

.form .form__controls-meta { display: inline-block; margin-top: 5px; font-family: 'Lato', sans-serif; font-size: 14px; font-style: italic; color: #9b9b9b; text-decoration: none; }

.form a.form__controls-meta:hover { text-decoration: underline; }

.form .form__meta { font-family: 'Lato', sans-serif; font-size: 17px; font-style: italic; color: #dc5d45; }

.form .btn { min-width: 140px; text-transform: none; }

.form .btn--small { min-width: 96px; font-weight: 700; }

.form .cols { margin: 0 -22px; }

.form .col { padding: 0 22px; }

/*  Form Light  */

.form--light { color: #fff; }

.form--light .form__row { margin-bottom: 12px; }

.form--light .form__label { margin-bottom: 7px; text-transform: none; }

.form--light .form__actions { margin-top: 36px; }

.form--light .btn { padding: 0 17px; font-weight: 700; }

/*  Form Coordinate  */

.form--coordinates .form__controls-meta { display: block; margin-top: 0; font-size: 13px; text-align: right; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.form .form__row { margin-bottom: 20px; }
	.form .form__row--gutter-none { margin: 0; }

	.form .form__label { margin-bottom: 5px; font-size: 13px; }
	
	.form .form__actions { margin: 20px 0; }
	
	.form .form__col--size-1 { -webkit-box-flex: 0; -ms-flex: 0 0 70px; flex: 0 0 70px; max-width: 70px; }
	
	.form .form__meta { font-size: 13px; }

	.form .btn { min-width: auto; width: 100%; height: 32px; font-size: 13px; line-height: 28px; }

	.form .cols { margin: 0; }
	.form .col { padding: 0; }
	
	.form--light .form__label { margin-bottom: 7px; font-size: 18px; }
	
	.form--light .form__actions { margin-bottom: 12px; }

	.form--light .field { height: 40px; }
	.form--light .btn { height: 40px; font-size: 16px; line-height: 36px; }
	
	.form--coordinates .form__row { margin-bottom: 16px; }
	.form--coordinates .form__row--gutter-none { margin: 0; }
	.form--coordinates .form__controls-meta { display: none; }
	.form--coordinates .btn { width: auto; height: 42px; font-size: 16px; line-height: 38px; }
}

/* ------------------------------------------------------------ *\
	Form Delivery
\* ------------------------------------------------------------ */

.form-delivery .form__row { position: relative; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; padding: 29px 0; border-bottom: 1px solid #2c414e; }

.form-delivery .form__image { min-width: 275px; text-align: center; }

.form-delivery .form__title { font-weight: 600; line-height: 1; text-transform: none; }

.form-delivery .form__entry { margin-bottom: 8px; font-family: 'Lato', sans-serif; font-size: 18px; font-style: italic; }

.form-delivery .form__label { position: absolute; top: 50%; right: 0; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); font-weight: 600; font-size: 25px; color: #dc5d45; text-transform: uppercase; }

.form-delivery .form__content { -webkit-box-flex: 1; -ms-flex: 1 1; flex: 1 1; padding-right: 20%; }

.form-delivery .form__foot { padding: 27px 0 0; text-align: center; }

.form-delivery .checkbox h5 { margin-bottom: 0; font-size: 20px; font-weight: 600; }

.form-delivery .checkbox--radio { width: 20px; }

.form-delivery .checkbox--radio label { position: absolute; top: 0; left: 0; z-index: 1; width: 100%; height: 100%; }

.form-delivery .checkbox--radio label:before { top: 50%; margin-top: -8px; }

.form-delivery .checkbox--radio label:after { top: 50%; margin-top: -10px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.form-delivery .form__row { display: block; padding: 0 0 5px 49px; margin-bottom: 11px; }

	.form-delivery .form__image { -webkit-transform: scale(.85); -ms-transform: scale(.85); transform: scale(.85); -webkit-transform-origin: 0% 50%; -ms-transform-origin: 0% 50%; transform-origin: 0% 50%; display: inline-block; min-width: 180px; margin-bottom: 10px; text-align: left; }

	.form-delivery .form__content { padding-right: 0; }

	.form-delivery .form__title { font-size: 18px; }

	.form-delivery .form__entry { font-size: 13px; }
	
	.form-delivery .form__label { top: 23px; font-size: 15px; }
	
	.form-delivery .form__foot { padding: 0 0 15px; border-bottom: 1px solid #2c414e; }
	.form-delivery .form__foot h5 { font-size: 13px; }
	
	.form-delivery .checkbox--radio label:before { top: 27px; left: 16px; margin-top: -8px; }
	.form-delivery .checkbox--radio label:after { top: 27px; left: 9px; margin-top: -10px; }
}

/* ------------------------------------------------------------ *\
	Icon
\* ------------------------------------------------------------ */

.icon-css-check { position: relative; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); -webkit-transform-origin: 100% 40%; -ms-transform-origin: 100% 40%; transform-origin: 100% 40%; display: inline-block; vertical-align: middle; width: 12px; height: 22px; border-width: 0 4px 4px 0; border-style: solid; border-color: #fff; font-size: 0; line-height: 1; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.icon-css-check { width: 10px; height: 18px; border-width: 0 3px 3px 0; }
}

/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

[class^="list-"] { list-style: none outside none; margin: 0; }

/* ------------------------------------------------------------ *\
	List Carets
\* ------------------------------------------------------------ */

.list-carets { font-size: 20px; font-weight: 500; }

.list-carets li { position: relative; padding-left: 20px; }

.list-carets li:before { content: ''; position: absolute; top: 10px; left: 3px; width: 9px; height: 12px; background-image     : url(/medias/sprite.@2x.png); background-position     : 98.68852459016394% 45.195729537366546%; background-size     : 3488.8888888888887% 2441.666666666667%; }

.list-carets li + li { margin-top: 3px; }

.list-carets a { text-decoration: none; }

.list-carets a:hover { opacity: .7; }

/* ------------------------------------------------------------ *\
	List Icons
\* ------------------------------------------------------------ */

.list-icons { font-size: 0; }

.list-icons li + li { margin-top: 5px; }

/* ------------------------------------------------------------ *\
	List Features
\* ------------------------------------------------------------ */

.list-features { font-family: 'Lato', sans-serif; font-size: 0; font-weight: 700; font-style: italic; color: #9b9b9b; }

.list-features li { display: inline-block; width: 50%; margin-bottom: 13px; font-size: 15px; }

.list-features i { margin-right: 4px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.list-features li { width: 100%; margin-bottom: 16px; }
	.list-features i { margin-right: 9px; }
	
}

/* ------------------------------------------------------------ *\
	List Actions
\* ------------------------------------------------------------ */

.list-actions { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; font-family: 'Lato', sans-serif; font-size: 13px; color: #9b9b9b; }

.list-actions li { margin-right: 15px; }

.list-actions i { margin-right: 5px; }

/* ------------------------------------------------------------ *\
	List Images
\* ------------------------------------------------------------ */

.list-images { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; }

.list-images a { display: block; }

.list-images a:hover { opacity: .7; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.list-images { margin: 0 -5px; }
	.list-images li { padding: 0 5px; }
}

/* ------------------------------------------------------------ *\
	List Dots
\* ------------------------------------------------------------ */

.list-dots li { position: relative; padding-left: 15px; margin-bottom: 9px; }

.list-dots li:before { content: ''; position: absolute; top: 11px; left: 2px; width: 6px; height: 6px; border-radius: 50%; background: #2c414e; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.list-dots li { padding-left: 11px; margin-bottom: 0; }
	.list-dots li:before { top: 7px; width: 4px; height: 4px; }
}

/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo { display: inline-block; vertical-align: middle; width: 110px; font-size: 0; text-decoration: none; }

.logo--large { width: 198px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.logo { width: 80px; }
}

/* ------------------------------------------------------------ *\
	Nav Trigger
\* ------------------------------------------------------------ */

.nav-trigger { display: inline-block; vertical-align: middle; line-height: 1; }

/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav { font-weight: 700; text-transform: uppercase; letter-spacing: 0.03em; text-align: center; }

.nav ul { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; }

.nav li { display: -webkit-box; display: -ms-flexbox; display: flex; }

.nav li:hover { color: #dc5d45; }

.nav li + li { margin-left: 46px; }

.nav .nav__item { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap; flex-flow: column wrap; text-decoration: none; }

.nav li.current .nav__item--green { color: #7eb620; }

.nav li.current .nav__item--cyan { color: #51becc; }

.nav li.current .nav__item--purple { color: #685991; }

.nav li.current .nav__item--yellow { color: #fac900; }

.nav li.current .nav__item--orange { color: #dc5d45; }

.nav li.current .nav__item--grey { color: #2c414e; }

@media ( min-width: 1025px ){
	.nav .nav__item--green:hover { color: #7eb620; }
	.nav .nav__item--cyan:hover { color: #51becc; }
	.nav .nav__item--purple:hover { color: #685991; }
	.nav .nav__item--yellow:hover { color: #fac900; }
	.nav .nav__item--orange:hover { color: #dc5d45; }
	.nav .nav__item--grey:hover { color: #2c414e; }
}

/* Small Desktop */

@media (max-width: 1200px) {
	.nav li + li { margin-left: 30px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.nav { -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .05); box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .05); }
	.nav ul { display: block; text-align: center; }
	.nav li { display: block; margin-bottom: 0; }
	.nav li + li { margin-left: 0; }
	
	.nav .nav__item i { -webkit-transform: scale(.6); -ms-transform: scale(.6); transform: scale(.6); }
}

/* ------------------------------------------------------------ *\
	Nav Simple
\* ------------------------------------------------------------ */

.nav-simple { font-size: 15px; font-weight: 700; text-transform: uppercase; }

.nav-simple ul { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin: 0; }

.nav-simple li + li { margin-left: 20px; }

.nav-simple a { text-decoration: none; }

@media ( min-width: 1025px ){
	.nav-simple a:hover { color: #dc5d45; }
}

/* Small Desktop */

@media (max-width: 1200px) {
	.nav-simple li + li { margin-left: 15px; }
}

/* ------------------------------------------------------------ *\
	Nav Utilities
\* ------------------------------------------------------------ */

.nav-utilities { font-weight: 700; text-transform: uppercase; }

.nav-utilities ul { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin: 0; }

.nav-utilities li + li { margin-left: 24px; }

.nav-utilities a { display: inline-block; min-height: 26px; text-decoration: none; }

.nav-utilities .nav__inner { position: relative; display: inline-block; vertical-align: middle; }

.nav-utilities small { position: absolute; top: 100%; left: 0; width: 100%; font-family: 'Lato', sans-serif; font-size: 11px; font-weight: 400; font-style: italic; text-transform: none; line-height: 0.55; }

.nav-utilities i { display: inline-block; vertical-align: middle; margin-right: 3px; }

@media ( min-width: 1025px ){
	.nav-utilities a:hover { color: #dc5d45; }
}

/* Small Desktop */

@media (max-width: 1200px) {
	.nav-utilities li + li { margin-left: 15px; }
}

/* ------------------------------------------------------------ *\
	Nav Mobile
\* ------------------------------------------------------------ */

.nav-mobile { font-size: 8px; text-transform: uppercase; }

.nav-mobile ul { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; }

.nav-mobile li { margin: 0 10px; }

.nav-mobile a { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap; flex-flow: column wrap; text-decoration: none; text-align: center; }

.nav-mobile i { display: block; margin-bottom: 3px; }

/* ------------------------------------------------------------ *\
	Orders
\* ------------------------------------------------------------ */

.orders { padding-top: 24px; }

.orders .orders__meta { display: inline-block; margin-bottom: 20px; font-size: 16px; color: #9b9b9b; }

.orders .orders__title { margin-bottom: 13px; color: #dc5d45; text-transform: uppercase; }

.orders .orders__body { padding-left: 6px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.orders { padding-top: 0; }
}

/* ------------------------------------------------------------ *\
	Order
\* ------------------------------------------------------------ */

.order { border-bottom: 1px solid #979797; }

.order .order__head { position: relative; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; padding: 10px 0 10px 30px; cursor: pointer; }

.order .order__head:before { content: ''; position: absolute; top: 20px; left: 0; width: 11px; height: 15px; background-image     : url(/medias/sprite.@2x.png); background-position     : 99.33993399339934% 38.84892086330935%; background-size     : 2854.5454545454545% 1953.3333333333335%; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.order .order__title { -webkit-box-flex: 1; -ms-flex-positive: 1; flex-grow: 1; font-family: 'Lato', sans-serif; }

.order .order__label { display: inline-block; width: 250px; padding-right: 10px; font-family: 'Lato', sans-serif; font-weight: 600; color: #dc5d45; }

.order .order__total { display: inline-block; width: 100px; padding: 3px 13px; text-align: center; background: #2c414e; font-size: 18px; font-weight: 600; color: #fff; }

.order .order__body { display: none; padding: 0 0 27px 33px; }

/*  Order Active  */

.order.active { border-bottom-color: transparent; }

.order.active .order__head:before { -webkit-transform: rotate(90deg); -ms-transform: rotate(90deg); transform: rotate(90deg); }

.order.active .order__body { display: block; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.order .order__head { padding: 5px 0 5px 14px; }
	.order .order__head:before { top: 7px; left: -5px; width: 8px; height: 11px; }
	
	.order .order__title { padding-right: 10px; font-size: 10px; }
	.order .order__label { width: 80px; font-size: 9px; font-weight: 700; }
	.order .order__total { padding: 2px 5px; font-size: 16px; }
	
	.order .order__body { padding: 0 0 5px 0; border-top: 1px solid #979797; }
	
	.order .table-order .table__meta,
	.order .table-order tbody { padding-left: 13px; }
}

/* ------------------------------------------------------------ *\
	Paging
\* ------------------------------------------------------------ */

.paging { position: relative; margin-right: 4px; font-family: 'Lato', sans-serif; font-size: 15px; text-transform: uppercase; color: #9b9b9b; }

.paging ul { display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin: 0 0 0 5px; list-style-type: none; }

.paging li { padding: 0 7px; }

.paging li.paging__separator { padding: 0; }

.paging li.paging__spacer { padding: 0 0 0 0px; }

.paging li.current { color: #2c414e; }

.paging a { text-decoration: none; }

.paging ul a:hover { color: #2c414e; }

.paging .paging__next,
.paging .paging__prev { font-family: 'Lato', sans-serif; font-size: 0; color: #9b9b9b; text-transform: none; }

.paging .paging__next:hover,
.paging .paging__prev:hover { opacity: .7; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.paging { position: absolute; text-align: center; top: 100%; left: 0; width: 100%; margin-top: 6px; }
	
	.paging .paging__prev,
	.paging .paging__next { position: absolute; top: 0; font-size: 12px; }
	
	.paging .paging__prev { left: 0; }
	.paging .paging__next { right: 0; }
	
	.paging .paging__prev svg,
	.paging .paging__next svg { display: inline-block; vertical-align: middle; width: 10px; height: 13px; margin: 0 3px; }
}

/* ------------------------------------------------------------ *\
	Popup
\* ------------------------------------------------------------ */

.popup { position: fixed; top: 0; left: 0; z-index: 50; width: 100%; height: 100%; opacity: 0; visibility: hidden; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.popup .popup__outer { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 0; border-radius: 0; background: rgba(44, 65, 78, 0.7); -webkit-appearance: none; -moz-appearance: none; appearance: none; }

.popup .popup__container { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 636px; padding: 26px 20px; border: 5px solid #dc5d45; background: #fff; max-height: 80%; overflow-y: auto; }

.popup .popup__title { margin-bottom: 35px; color: #dc5d45; text-align: center; }

.popup.active { opacity: 1; visibility: visible; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.popup .popup__container { width: 84%; padding: 26px; }
	
	.popup .popup__title { margin-bottom: 15px; font-size: 20px; font-weight: 700; line-height: 1.1; }
}

/* ------------------------------------------------------------ *\
	Price
\* ------------------------------------------------------------ */

.price { position: relative; display: inline-block; vertical-align: middle; font-size: 23px; font-weight: 500; line-height: 1; text-align: center; }

.price .price__discount { display: block; margin-top: 2px; font-size: 18px; font-weight: 500; color: #dc5d45; }

.price .price__label { display: none; }

.price .price__old { display: block; text-decoration: line-through; }

.price .price__meta { display: block; font-size: 10px; font-weight: 400; }

/* Small Desktop */

@media (max-width: 1200px) {
	.price { font-size: 18px; }
	.price .price__discount { font-size: 14px; }
	.price .price__label { display: block; font-size: 11px; font-weight: 500; color: #9b9b9b; }
}

/* ------------------------------------------------------------ *\
	Product Single
\* ------------------------------------------------------------ */

.product-single { padding-top: 23px; margin-bottom: 45px; }

.product-single .product__container { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; }

.product-single .product__aside { width: 256px; text-align: right; }

.product-single .product__aside-inner { position: relative; height: 100%; padding: 64px 9px 10px; border: 2px solid #2c414e; border-radius: 9px; }

.product-single .product__aside-entry { margin-bottom: 20px; font-family: 'Lato', sans-serif; font-size: 13px; font-style: italic; line-height: 1.2; }

.product-single .product__aside-entry strong { display: block; }

.product-single .product__aside-entry span { color: #9b9b9b; }

.product-single .product__main { width: calc(100% - 294px); }

.product-single .product__main-inner { position: relative; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; height: 100%; padding: 29px 30px 17px; border: 2px solid #2c414e; border-radius: 9px; }

/*  Product Single Images  */

.product-single .product__images { width: 42%; padding: 0 10px; text-align: center; }

.product-single .product__image { overflow: hidden; height: 250px; margin-bottom: 13px; line-height: 250px; }

.product-single .product__image img { max-height: 100%; cursor: pointer; }

/*  Product Single Content  */

.product-single .product__content { width: 49%; }

.product-single .product__title { margin-bottom: 8px; font-weight: 600; line-height: 1; text-transform: none; }

.product-single .product__meta { display: block; margin-top: 3px; font-family: 'Lato', sans-serif; font-size: 13px; font-weight: 400; color: #9b9b9b; }

.product-single .product__entry { margin-bottom: 13px; min-height: 90px; font-family: 'Lato', sans-serif; font-style: italic; line-height: 1.3; }

.product-single .product__entry p { margin-bottom: 7px; }

/*  Product Single Tag  */

/*.product-single .product__tags { position: absolute; top: 7px; right: 10px; pointer-events: none; }*/

.product-single .product__tag { display: block; padding: 2px 12px; border-radius: 2px; margin-bottom: 2px; font-size: 17px; font-weight: 600; color: #fff; text-transform: uppercase; }

.product-single .product__tag--promotion { background: #d70056; }

.product-single .product__tag--new { background: #dc5d45; }

.product-single .product__tag-small { display: inline-block; vertical-align: middle; padding: 2px 7px; border-radius: 2px; margin-bottom: 12px; background: #7eb620; font-size: 17px; font-weight: 600; line-height: 1; color: #fff; text-transform: uppercase; }

/*  Product Single Price  */

.product-single .product__prices { position: absolute; top: -2px; right: -2px; }

.product-single .product__price { display: inline-block; min-width: 130px; padding: 3px 10px; background: #2c414e; font-size: 30px; font-weight: 600; color: #fff; text-align: center; }

.product-single .product__price-old { display: inline-block; min-width: 76px; font-size: 17px; font-weight: 600; text-align: center; text-decoration: line-through; }

.product-single .list-icons { position: absolute; top: 12px; left: 9px; }

.product-single .list-features { margin-bottom: 24px; }

.product-single .rating { margin-bottom: 19px; }

.product-single .field-number { margin-bottom: 23px; }

.product-single .socials { margin-bottom: 48px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.product-single { padding: 3px 11px 0; margin-bottom: 12px; }
	
	.product-single .product__container { display: block; padding: 0 9px; margin-bottom: 21px; }
	
	.product-single .product__main { width: 100%; margin-bottom: 15px; }
	.product-single .product__main-inner { display: block; padding: 20px 18px 15px; }
	
	.product-single .product__images { width: 100%; padding: 0; margin-bottom: 19px; }
	.product-single .product__image { height: 180px; margin-bottom: 6px; line-height: 180px; }
	
	.product-single .product__content { width: 100%; }
	
	.product-single .product__aside { width: 100%; }
	.product-single .product__aside-inner { padding-bottom: 17px; }
	
	.product-single .product__title { margin-bottom: 11px; font-size: 30px; }
	
	.product-single .product__meta { margin-top: 5px; font-size: 11px; }
	
	.product-single .product__entry { min-height: 0; margin-bottom: 17px; font-size: 13px; }
	.product-single .product__entry p { margin-bottom: 9px; }

	.product-single .product__tags { top: 8px; }
	.product-single .product__tag { padding: 3px 2px; font-size: 8px; }

	.product-single .list-icons { top: 7px; }

	.product-single .list-features { margin-bottom: 0; }

	.product-single .list-actions { display: none; }

	.product-single .rating { margin-bottom: 0; }
	.product-single .rating svg { width: 13px; height: 13px; }
	.product-single .rating li + li { margin-left: 4px; }
}

/* ------------------------------------------------------------ *\
	Products Boxes
\* ------------------------------------------------------------ */

.products-boxes { margin: 0 -16px; }

.products-boxes .product-box { padding: 0 16px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.products-boxes { margin: 0; text-align: center; }
}

/* ------------------------------------------------------------ *\
	Product Box
\* ------------------------------------------------------------ */

.product-box { display: inline-block; margin-bottom: 35px; text-align: center; }

.product-box .product__inner { position: relative; display: block; width: 236px; min-height: 236px; padding: 10px 10px 20px; border: 2px solid #2c414e; border-radius: 8px; background: #fff; text-decoration: none; -webkit-transition: border-color .3s; -o-transition: border-color .3s; transition: border-color .3s; }

.product-box .product__image { overflow: hidden; display: block; height: 120px; margin-bottom: 3px; line-height: 120px; text-decoration: none; }

.product-box .product__image img { max-height: 100%; }

.product-box .product__title { margin-bottom: 5px; font-weight: 600; line-height: 1; height: 44px; overflow: hidden; }

.product-box .product__rating { margin-bottom: 8px; }

/*  Product Box Button  */

.product-box .product__btn { position: relative; overflow: hidden; display: inline-block; vertical-align: middle; width: 23px; height: 23px; border: 2px solid #2c414e; border-radius: 4px; margin: 0 2px; line-height: 19px; font-size: 0; text-decoration: none; text-align: center; }

/*  Product Box Price  */

.product-box .product__prices { position: absolute; bottom: -2px; right: -2px; }

.product-box .product__price { display: inline-block; vertical-align: middle; padding: 4px 9px; background: #2c414e; font-size: 18px; font-weight: 600; line-height: 1; color: #fff; -webkit-transition: background .3s; -o-transition: background .3s; transition: background .3s; }

.product-box .product__price-old { display: inline-block; vertical-align: middle; min-width: 41px; font-size: 12px; text-decoration: line-through; text-align: center; line-height: 1; }

/*  Product Box Tag  */

/*.product-box .product__tags { position: absolute; top: 5px; right: 4px; pointer-events: none; }*/

.product-box .product__tag { display: block; vertical-align: middle; padding: 1px 3px; border-radius: 2px; margin-bottom: 2px; font-size: 12px; font-weight: 600; color: #fff; text-transform: uppercase; }

.product-box .product__tag--promotion { background: #d70056; }

.product-box .product__tag--new { background: #dc5d45; }

.product-box .product__tag--stock { background: #7eb620; }

.product-box .list-icons { position: absolute; top: 8px; left: 8px; }

.product-box .rating .rating__meta { font-size: 11px; }

/*  Product Box Small  */

.product-box--small { margin-bottom: 10px; }

.product-box--small .product__inner { width: 158px; min-height: 158px; padding: 5px; }

.product-box--small .product__image { height: 70px; line-height: 70px; }

.product-box--small .product__title { margin-bottom: 3px; height: 24px; font-size: 12px; }

.product-box--small .product__rating { margin-bottom: 2px; }

.product-box--small .product__price { padding: 3px 7px; font-size: 14px; }

.product-box--small .product__price-old { font-size: 10px; }

.product-box--small .product__tag { padding: 3px 4px; font-size: 8px; }

.product-box--small .product__btn { width: 18px; height: 18px; line-height: 14px; }

.product-box--small .product__btn i { width: 80%; height: 80%; }

.product-box--small .list-icons { top: 5px; left: 5px; }

.product-box--small .list-icons li + li { margin-top: 2px; }

.product-box--small .rating .rating__meta { font-size: 9px; }

@media ( min-width: 1025px ){
	.product-box .product__inner:hover { border-color: #7eb620; }
	.product-box .product__inner:hover .product__price { background: #7eb620; }
	
	.product-box .product__btn:hover { opacity: .7; }
}

/* Small Desktop */

@media (max-width: 1200px) {
	.product-box .product__tag { font-size: 8px; }
	.product-box .list-icons { top: 3px; left: 3px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.product-box { margin-bottom: 17px; }
	.product-box .product__inner { width: 180px; min-height: 180px; padding-top: 5px; }
	.product-box .product__image { height: 88px; line-height: 88px; }
	.product-box .product__title { margin-bottom: 1px; font-size: 16px; font-weight: 600; height: 33px; }
	.product-box .product__price { padding: 3px 9px; font-size: 16px; }
	.product-box .product__price-old { font-size: 13px; }	
	
	.product-box .product__rating { margin-bottom: 2px; }
	
	.product-box .product__btn { width: 20px; height: 20px; border-width: 1px; line-height: 18px; }
	.product-box .product__btn i { width: 80%; height: 80%; }
}

/* ------------------------------------------------------------ *\
	Product Simple
\* ------------------------------------------------------------ */

.product-simple { text-align: center; }

.product-simple .product__image { margin-bottom: 30px; }

.product-simple .product__title { margin-bottom: 60px; font-size: 28px; font-weight: 600; text-transform: none; }

.product-simple .product__actions { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; max-width: 470px; margin: 0 auto; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.product-simple .product__image { max-width: 150px; margin: 0 auto 13px; }
	.product-simple .product__title { margin-bottom: 18px; font-size: 20px; font-weight: 600; }
	.product-simple .product__actions { -webkit-box-orient: vertical; -webkit-box-direction: reverse; -ms-flex-flow: column-reverse wrap; flex-flow: column-reverse wrap; }
	.product-simple .btn { width: 100%; padding: 0 5px; margin-bottom: 10px; font-size: 13px; }
	.product-simple .btn i { margin-right: 3px; }
	.product-simple .btn .ico-cart-large { width: 28px; height: 22px; }
}

/* ------------------------------------------------------------ *\
	Promo
\* ------------------------------------------------------------ */

.promo { width: 254px; }

.promo .promo__controls { position: relative; }

.promo .promo__field { width: 100%; height: 48px; padding: 0 60px 0 21px; border: 1px solid #979797; border-radius: 8px; background: transparent; font-family: 'Lato', sans-serif; font-size: 18px; font-weight: 300; font-style: italic; color: #253553; -webkit-appearance: none; -moz-appearance: none; appearance: none; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.promo .promo__btn { position: absolute; top: 0; right: 0; width: 54px; height: 48px; border: 0; border-radius: 8px; background: #2c414e; -webkit-appearance: none; -moz-appearance: none; appearance: none; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.promo .promo__btn:before { content: ''; position: absolute; top: 4px; right: 18px; z-index: 1; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); width: 16px; height: 32px; border-width: 0 5px 5px 0; border-style: solid; border-color: #fff;  }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.promo { width: 160px; }
	.promo .promo__field { height: 32px; padding: 0 30px 0 8px; font-size: 14px; }
	
	.promo .promo__btn { width: 32px; height: 32px; }
	.promo .promo__btn:before { top: 4px; right: 10px; width: 10px; height: 20px; border-width: 0 3px 3px 0; }
}

/* ------------------------------------------------------------ *\
	Rating
\* ------------------------------------------------------------ */

.rating { line-height: 1; font-size: 0; }

.rating ul { display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; vertical-align: middle; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin: 0; list-style-type: none; }

.rating li + li { margin-left: 1px; }

.rating .rating__meta { display: inline-block; vertical-align: middle; margin-left: 5px; font-family: 'Lato', sans-serif; font-size: 9px; font-weight: 700; font-style: italic; color: #9b9b9b; }

.rating--large li + li { margin-left: 2px; }

.rating--large .rating__meta { margin-left: 10px; font-size: 11px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.rating svg { width: 7px; height: 7px; }
}

/* ------------------------------------------------------------ *\
	Rating Slider
\* ------------------------------------------------------------ */

.rating-slider + .rating-slider { margin-top: 22px; }

.rating-slider .rating__track { position: relative; overflow: hidden; display: block; width: 100%; height: 12px; border-radius: 6px; margin-bottom: 4px; background: rgba(255, 255, 255, 0.45); }

.rating-slider .rating__track-fill { position: absolute; top: 0; left: 0; height: 100%; background: #fff; border-radius: 6px; }

.rating-slider .rating__title { margin-bottom: 10px; font-size: 20px; font-weight: 600; text-transform: uppercase; }

.rating-slider .rating__foot { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; line-height: 1; }

.rating-slider .rating__meta { display: inline-block; margin-bottom: 3px; font-family: 'Lato', sans-serif; font-size: 12px; font-style: italic; line-height: 1; }

.rating-slider .rating__meta i { margin-right: 2px; }

/*  Rating Slider Green  */

.rating-slider--green .rating__track { background: rgba(126, 182, 32, 0.25); }

.rating-slider--green .rating__track-fill { background: #7eb620; }

.rating-slider--green .rating__meta { color: #7eb620; }

/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

.search { position: relative; width: 231px; }

.search .search__field { width: 100%; height: 32px; padding: 0 30px 0 9px; border: 1px solid #fff; border-radius: 0; background: #fff; font-size: 13px; font-weight: 600; color: #2c414e; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.search .search__field:focus { border-color: #dc5d45; }

.search .search__btn { position: absolute; top: 0; right: 0; width: 30px; height: 32px; border: 0; border-radius: 0; background: transparent; line-height: 0; text-align: center; -webkit-appearance: none; -moz-appearance: none; appearance: none; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.search .search__btn:hover { opacity: .7; }

/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section { margin-bottom: 24px; }

.section .section__container { max-width: 890px; margin: 0 auto; }

.section .section__container--large { max-width: 940px; }

.section .section__container--xlarge { max-width: 974px; }

.section .section__inner { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; }

.section .section__aside { width: 26%; }

.section .section__content { width: 69%; }

.section .section__head { padding: 38px 0; margin-bottom: 6px; }

.section .section__head-entry { font-family: 'Lato', sans-serif; font-size: 25px; font-weight: 300; text-align: center; }

.section .section__title { position: relative; text-align: center; }

.section .section__title:before { content: ''; position: absolute; top: 58%; left: 0; width: 100%; height: 2px; background: #dc5d45; }

.section .section__title > span { position: relative; z-index: 1; display: inline-block; padding: 0 24px; background: #fff; }

/*  Section Gutter Top  */

.section--gutter-top { padding-top: 30px; }

.section--gutter-top .section__head { margin-bottom: 25px; }

/*  Section Gutter Bottom  */

.section--gutter-bottom { margin-bottom: 88px; }

/*  Section Table  */

.section--table .section__head { padding: 48px 0 15px; }

/*  Section Contact  */

.section--contact { margin-bottom: 87px; }

.section--contact .section__head { padding: 88px 0 54px; }

.section--contact .section__title { margin-bottom: 5px; line-height: 1; }

.section--contact .entry-box { width: 390px; margin: 35px 0 0 60px; }

/*  Section Coordinates  */

.section--coordinates .section__head { padding: 18px 0; margin-bottom: 26px; }

.section--coordinates .section__head-entry { font-size: 20px; font-weight: 400; }

.section--coordinates .section__title { line-height: 1.1; }

/*  Section About  */

.section--about .section__head { padding: 15px 0 47px; }

/* Small Desktop */

@media (max-width: 1200px) {
	.section { padding: 13px 0 0; margin-bottom: 10px; }
	.section .section__head { padding: 0; margin-bottom: 22px; }
	.section .section__title:before { height: 1px; }
	.section .section__title > span { padding: 0 5px; }
	
	.section--gutter-bottom { margin-bottom: 26px; }
	
	.section--contact { margin-bottom: 40px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.section .section__inner { -webkit-box-orient: vertical; -webkit-box-direction: reverse; -ms-flex-flow: column-reverse wrap; flex-flow: column-reverse wrap; }
	.section .section__aside { width: 100%; margin-bottom: 28px; }
	.section .section__content { width: 100%; }

	.section .section__head-entry { font-size: 12px; font-weight: 400; letter-spacing: .01em; }
	
	.section--contact { padding-top: 22px; }
	.section--contact .section__head { margin-bottom: 24px; }
	.section--contact .section__title { margin-bottom: 1px; }
	
	.section--contact .entry-box { width: 100%; margin: 0; }
	
	.section--coordinates .section__head-entry { font-size: 13px; }
}

/* ------------------------------------------------------------ *\
	Section Tabs
\* ------------------------------------------------------------ */

.section-tabs { padding-top: 20px; padding-bottom: 20px; }

.section-tabs .section__container { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; }

.section-tabs .section__aside { width: 30%; }

.section-tabs .section__content { width: 68%; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.section-tabs { padding: 0 13px; }
	.section-tabs .section__container { display: block; }
	.section-tabs .section__content { width: 100%; }
	.section-tabs .section__aside { display: none; }
}

/* ------------------------------------------------------------ *\
	Section Confirm
\* ------------------------------------------------------------ */

.section-confirm { padding: 12px 0 6px; text-align: center; }

.section-confirm .section__head { margin-bottom: 50px; }

.section-confirm .section__head-entry { font-family: 'Lato', sans-serif; font-size: 20px; }

.section-confirm .section__head-entry small { display: block; margin-top: 3px; font-size: 16px; font-style: italic; letter-spacing: -.014em; color: #9b9b9b; }

.section-confirm .section__head-entry i { vertical-align: baseline; margin-right: 5px; }

.section-confirm .section__title { margin-bottom: 31px; font-weight: 700; letter-spacing: -.014em; color: #dc5d45; text-transform: uppercase; }

.section-confirm .section__entry { display: inline-block; font-family: 'Lato', sans-serif; font-size: 20px; line-height: 1.3; letter-spacing: -.014em; color: #9b9b9b; text-align: left; }

.section-confirm .section__entry p { margin-bottom: 34px; }

.section-confirm .section__entry h5 { margin-bottom: 8px; font-size: 20px; color: #2c414e; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.section-confirm { padding: 0; margin-bottom: 30px; }
	.section-confirm .section__head { margin-bottom: 19px; }
	.section-confirm .section__head-entry { font-size: 12px; }
	.section-confirm .section__head-entry small { margin-top: 15px; font-size: 13px; }
	.section-confirm .section__head-entry i { display: none; }
	
	.section-confirm .section__title { margin-bottom: 8px; font-size: 20px; }
	
	.section-confirm .section__entry { display: block; font-size: 13px; line-height: 1.4; }
	.section-confirm .section__entry h5 { margin-bottom: 0; font-size: 16px; }
	.section-confirm .section__entry p { margin-bottom: 40px; }
	.section-confirm .section__entry p:last-child { margin-bottom: 0; }
}

/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider .slider__clip { position: relative; padding: 0 98px; }

/*  Slider Slides  */

.slider .slider__slides { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin: 0 -18px; }

.slider .slider__slide { padding: 0 18px; }

/*  Slider Arrows  */

.slider .slider__arrows { position: absolute; top: 50%; left: 0; width: 100%; }

.slider .slider__arrow { position: absolute; top: 0; -webkit-transform: translateY(-56%); -ms-transform: translateY(-56%); transform: translateY(-56%); display: inline-block; vertical-align: middle; border: 0; border-radius: 0; background: transparent; -webkit-appearance: none; -moz-appearance: none; appearance: none; text-decoration: none; text-align: center; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.slider .slider__arrow:hover { opacity: .7; }

.slider .slider__arrow--prev { left: 0; }

.slider .slider__arrow--next { right: 0; }

/* Small Desktop */

@media (max-width: 1200px) {
	.slider .slider__clip { padding: 0 40px; }
	.slider .slider__slides { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; margin: 0 -10px; }
	.slider .slider__slide { padding: 0 10px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.slider .slider__arrow { width: 18px; height: 23px; }
	.slider .slider__arrow i { width: 100%; height: 100%; }
	
}

/* ------------------------------------------------------------ *\
	Slider Images
\* ------------------------------------------------------------ */

.slider-images { position: relative; }

/*  Slider Images Dots  */

.slider-images .slider__dots { position: absolute; bottom: 32px; left: 50%; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%); transform: translateX(-50%); font-size: 0; }

.slider-images .slider__dots ul { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; list-style-type: none; }

.slider-images .slider__dots li { margin: 0 8px; }

.slider-images .slider__dots button { display: inline-block; vertical-align: middle; width: 30px; height: 30px; border: 4px solid #d6d6d6; border-radius: 50%; background: #fff; -webkit-appearance: none; -moz-appearance: none; appearance: none; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.slider-images .slider__dots li.active button { border-color: #fff; background: #dc5d45; }

/* Small Desktop */

@media (max-width: 1200px) {
	.slider-images .slider__dots { bottom: 20px; }
	.slider-images .slider__dots button { width: 26px; height: 26px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.slider-images .slider__dots { bottom: 4px; }
	.slider-images .slider__dots li { margin: 0 2px; }
	.slider-images .slider__dots button { width: 6px; height: 6px; border-width: 1px; }
}

/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials { width: 140px; }

.socials .socials__label { display: inline-block; vertical-align: middle; margin-right: 5px; font-family: 'Lato', sans-serif; color: #253553; }

.socials ul { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; list-style-type: none; font-size: 0; }

.socials li { display: inline-block; margin: 0 5px 10px; }

.socials a:hover { opacity: .7; }

/*  Socials Small  */

.socials--small { width: 100%; }

.socials--small ul { display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; vertical-align: middle; }

.socials--small li { margin: 0 4px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.socials { width: 100%; }
	.socials li { margin: 0; }
	.socials a { display: inline-block; width: 50px; height: 50px; }
	.socials a i { width: 100%; height: 100%; }
	
	.socials--small li { margin: 0 5px; }
	.socials--small a { width: 23px; height: 23px; }
}

/* ------------------------------------------------------------ *\
	Sort
\* ------------------------------------------------------------ */

.sort .sort__container { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; padding: 12px 10px; margin-bottom: 30px; background: rgba(126, 182, 32, 0.15); }

.sort .sort__right,
.sort .sort__left { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; }

.sort .select { margin-right: 34px; }

.sort .sort__title-mobile { display: none; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.sort { position: relative; margin-bottom: 43px; font-size: 12px; }
	.sort .sort__container { padding: 8px 14px 0; }
	.sort .sort__title-mobile { display: block; padding: 3px 15px; margin-bottom: 4px; border-radius: 6px; background: #7eb620; font-size: 15px; font-weight: 600; letter-spacing: 0.03em; color: #fff; }

	.sort--mobile .sort__container { padding: 0; }
	.sort--mobile .sort__left { display: none; }
	.sort--mobile .select { display: none; }
}

/* ------------------------------------------------------------ *\
	Steps
\* ------------------------------------------------------------ */

.steps { padding: 94px 0 100px; }

/*  Steps Head  */

.steps .steps__head { margin-bottom: 75px; }

/*  Steps Body  */

.steps .steps__body { margin-bottom: 30px; }

/*  Steps Nav  */

.steps .steps__nav { max-width: 720px; margin: 0 auto; text-align: center; }

.steps .steps__nav ul { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; }

.steps .steps__nav li { position: relative; width: 100%; color: #9b9b9b; }

.steps .steps__nav li + li:before { content: ''; position: absolute; top: 25px; right: calc(50% + 25px); width: calc(100% - 50px); border: 1px dashed #9b9b9b; }

.steps .steps__nav-icon { display: inline-block; vertical-align: middle; width: 50px; height: 50px; border: 3px solid #9b9b9b; border-radius: 50%; margin-bottom: 8px; background: #9b9b9b; font-size: 25px; font-weight: 600; line-height: 44px; color: #fff; text-align: center; }

.steps .steps__nav-title { display: block; font-size: 20px; font-weight: 600; }

.steps li.active .steps__nav-icon { border-color: #dc5d45; background: #fff; color: #dc5d45; }

.steps li.active .icon-css-check { border-color: #dc5d45; }

.steps li.active { color: #dc5d45; }

.steps li.active + li.active:before { border: 1px solid #dc5d45; }

/*  Steps Gutters Small  */

.steps--gutters-small { padding-bottom: 0; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.steps { padding: 26px 0 40px; }

	.steps .steps__head { margin-bottom: 30px; }

	.steps .steps__body { margin-bottom: 10px; }

	.steps .steps__nav li + li:before { top: 19px; right: calc(50% + 19px); width: calc(100% - 38px); }
	.steps .steps__nav-icon { width: 38px; height: 38px; border-width: 2px; font-size: 20px; line-height: 34px; }
	.steps .steps__nav-icon .ico-check { width: 18px; height: 15px; }
	.steps .steps__nav-title { font-size: 10px; }
}

/* ------------------------------------------------------------ *\
	Step
\* ------------------------------------------------------------ */

.step .step__container { max-width: 910px; margin: 0 auto; }

.step .step__container--small { max-width: 744px; }

.step .step__actions { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; margin-top: 47px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.step .step__actions { margin-top: 20px; }
}

/* ------------------------------------------------------------ *\
	Subscribe
\* ------------------------------------------------------------ */

.subscribe { background: #dc5d45; color: #fff; }

.subscribe .subscribe__inner { position: relative; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; }

.subscribe .subscribe__entry { width: 37%; margin-left: -5px; font-size: 35px; font-weight: 500; line-height: 1; }

/*  Subscribe Form  */

.subscribe .subscribe__form { width: 63%; }

.subscribe .subscribe__form-inner { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; padding: 37px 0; margin: 0 -5px; }

.subscribe .subscribe__field { width: 100%; height: 48px; padding: 0 16px; border: 0; border-radius: 8px; background: #fff; font-size: 18px; color: #253553; -webkit-appearance: none; -moz-appearance: none; appearance: none; }

.subscribe .subscribe__row { width: 100%; padding: 0 5px; }

.subscribe .subscribe__actions { margin-left: 10px; }

.subscribe .subscribe__btn { display: inline-block; vertical-align: middle; min-width: 144px; height: 48px; border: 3px solid #fff; border-radius: 8px; background: transparent; font-size: 20px; font-weight: 700; line-height: 42px; color: #fff; text-align: center; -webkit-appearance: none; -moz-appearance: none; appearance: none; -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.subscribe .subscribe__btn:hover { background: #fff; color: #dc5d45; }

/* Small Desktop */

@media (max-width: 1200px) {
	.subscribe .subscribe__entry { margin-left: 0; }
	.subscribe .subscribe__actions { margin-left: 10px; padding-right: 5px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.subscribe { text-align: center; }
	
	.subscribe .subscribe__inner { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap; flex-flow: column wrap; padding: 12px 0 5px; }
	
	.subscribe .subscribe__form { width: 100%; padding: 0 13px; }
	.subscribe .subscribe__form-inner { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap; flex-flow: column wrap; padding: 15px 0; }
	.subscribe .subscribe__row { margin-bottom: 8px; }
	
	.subscribe .subscribe__entry { width: 100%; font-size: 20px; font-weight: 600; line-height: 1.05; }
	
	.subscribe .subscribe__actions { margin: 9px 0 0; }
}

/* ------------------------------------------------------------ *\
	Tabs
\* ------------------------------------------------------------ */

.tabs .tabs__head { margin-bottom: 12px; }

/*  Tabs Nav  */

.tabs .tabs__nav { font-size: 0; }

.tabs .tabs__nav ul { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; }

.tabs .tabs__nav li { margin-right: 2px; }

.tabs .tabs__nav li.active a { background: #51becc; }

.tabs .tabs__nav a { display: inline-block; vertical-align: middle; padding: 1px 10px; background: #9b9b9b; font-size: 20px; font-weight: 600; color: #fff; text-decoration: none; }

.tabs .tabs__nav a:hover { opacity: .7; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.tabs .tabs__head { display: none; }
}

/* ------------------------------------------------------------ *\
	Tab
\* ------------------------------------------------------------ */

.tab { display: none; }

.tab.active { display: block; }

.tab .tab__title { display: none; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.tab { display: block; margin-bottom: 18px; }
	.tab .tab__title { display: inline-block; vertical-align: middle; padding: 1px 10px; margin-bottom: 17px; background: #9b9b9b; font-size: 20px; font-weight: 600; color: #fff; }
}

/* ------------------------------------------------------------ *\
	Tables
\* ------------------------------------------------------------ */

.tables { margin-bottom: 125px; }

.tables .tables__total { padding-bottom: 48px; border-top: 1px solid #2c414e; }

.tables .tables__total-container { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; padding: 39px 0 34px; border-bottom: 1px solid #2c414e; }

.tables .tables__total-content { width: 465px; margin-top: -5px; }

.tables .tables__total-title { font-size: 23px; font-weight: 600; letter-spacing: .35px; text-align: right; text-transform: none; }

.tables .tables__total-title small { display: block; font-family: 'Lato', sans-serif; font-size: 15px; font-weight: 400; font-style: italic; line-height: 1.2; }

.tables .tables__total-entry { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; margin-bottom: 52px; }

.tables .tables__total-entry--gutter-small { margin-bottom: 6px; }

.tables .tables__total-entry:last-child { margin-bottom: 0; }

.tables .tables__total-aside { -ms-flex-negative: 0; flex-shrink: 0; width: 155px; text-align: center; }

.tables .tables__total-aside--align-right { text-align: right; }

.tables .tables__total-shipping { font-size: 23px; font-weight: 400; }

.tables .tables__total-tag { display: inline-block; min-width: 126px; padding: 3px 10px; background: #2c414e; font-weight: 600; font-size: 23px; color: #fff; text-align: center; }

.tables .tables__total-tag--narrow { min-width: 100px; }

/*  Tables Actions  */

.tables .tables__actions { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; }

/*  Tables Gutter None  */

.tables--gutter-none { margin-bottom: 0; }

/* Small Desktop */

@media (max-width: 1200px) {
	.tables .tables__total-price { font-size: 23px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.tables { margin-bottom: 63px; }
	.tables .tables__total { padding: 17px 0 33px; }
	
	.tables .tables__total-container { display: block; padding-bottom: 21px; }
	
	.tables .tables__total-content { width: 100%; }
	
	.tables .tables__total-title { width: 50%; padding-top: 2px; font-size: 18px; font-weight: 600; letter-spacing: .27px; }
	.tables .tables__total-title small { font-size: 11px; letter-spacing: .26px; }
	
	.tables .tables__total-entry { margin-bottom: 30px; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }
	.tables .tables__total-entry + .tables__total-entry { margin-top: 20px; }
	.tables .tables__total-entry--gutter-small + .tables__total-entry--gutter-small { margin-top: 6px; }
	
	.tables .tables__total-aside { width: auto; }
	
	.tables .tables__total-tag { min-width: auto; padding: 2px 14px; }
	
	.tables .tables__actions .btn--default { min-width: auto; border: 0; background: transparent; font-size: 0; }

	.tables .promo { margin-bottom: 30px; }
	
	.tables--gutter-none { margin-bottom: 0; }
	.tables--gutter-none .tables__total { padding: 0; }
}

/* ------------------------------------------------------------ *\
	Table Cart
\* ------------------------------------------------------------ */

.table-cart { margin-bottom: 36px; }

.table-cart table { table-layout: fixed; border-collapse: collapse; }

/*  Table Head  */

.table-cart thead { border-bottom: 1px solid #2c414e; }

.table-cart th { padding-bottom: 8px; font-size: 20px; font-weight: 500; color: #2c414e; }

.table-cart th:first-child { padding-left: 49px; text-align: left; }

.table-cart th i { vertical-align: text-top; margin-right: 6px; }

.table-cart td:last-child { text-align: right; }

.table-cart td { padding: 31px 16px 0; text-align: center; }

.table-cart td:first-child { text-align: left; }

.table-cart td:last-child { padding: 31px 0 0; }

/*  Table Cart Simple  */

.table-cart--simple { padding: 12px 0 33px; margin: 0; border-width: 1px 0 0; border-style: solid; border-color: #2c414e; }

.table-cart--simple .table__title { font-size: 20px; font-weight: 600; letter-spacing: -.014em; color: #dc5d45; }

/* Small Desktop */

@media (max-width: 1200px) {
	.table-cart th { padding-bottom: 4px; font-size: 16px; }
	
	.table-cart td,
	.table-cart td:last-child { padding-top: 15px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.table-cart { margin-bottom: 0; }
	
	.table-cart colgroup,
	.table-cart thead { display: none; }

	.table-cart table,
	.table-cart tbody,
	.table-cart tr,
	.table-cart td { display: block; width: 100%; padding: 0; text-align: left; }

	.table-cart tr { padding-bottom: 15px; }
	
	.table-cart td:last-child { display: none; }
	
	/*  Table Cart Simple  */
	.table-cart--simple { padding: 21px 0 0; }
	
	.table-cart--simple tr { padding: 10px 0; margin-bottom: 0; }
	.table-cart--simple tr:last-child { border-bottom: 0; }
	.table-cart--simple td:last-child { display: block; padding: 0; }
	
	.table-cart--simple .table__title { margin-bottom: 13px; font-size: 16px; }
}

/* ------------------------------------------------------------ *\
	Table Gift
\* ------------------------------------------------------------ */

.table-gift { position: relative; }

.table-gift table { width: 70.6%; margin-left: auto; }

.table-gift tr:first-child td { padding-bottom: 22px; }

.table-gift tr:last-child td { border-bottom: 0; }

.table-gift td { padding: 4px 0; border-bottom: 1px dashed #2c414e; }

.table-gift td:last-child { text-align: right; }

.table-gift .table__icon { position: absolute; top: 13px; left: 28px; }

.table-gift .table__title { line-height: 1.4; }

.table-gift .table__entry { display: inline-block; vertical-align: middle; padding: 0 5px; font-family: 'Lato', sans-serif; font-size: 19px; }

.table-gift .table__meta { display: block; font-size: 15px; font-weight: 500; color: #9b9b9b; }

/*  Table Gift Foot  */

.table-gift .table__foot { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; padding: 12px 25px 12px 63px; background: rgba(216, 216, 216, 0.15); font-family: 'Lato', sans-serif; font-size: 15px; font-style: italic; letter-spacing: 0.02em; }

.table-gift .table__foot-entry { color: #dc5d45; }

.table-gift .table__foot-entry svg { display: inline-block; margin-right: 13px; }

.table-gift .table__foot-actions { color: #9b9b9b; }

/* Small Desktop */

@media (max-width: 1200px) {
	.table-gift .table__title { font-size: 18px; }
	.table-gift .table__meta { font-size: 14px; }
	.table-gift .table__entry { font-size: 16px; }

	.table-gift .table__foot { padding: 10px 5px; font-size: 14px; }
	.table-gift .table__foot-entry svg { margin-right: 5px; }
}

/* Tablet Portrait */

@media (max-width: 1023px) {
	.table-gift { border-bottom: 1px solid #2c414e; }
	.table-gift table { margin-bottom: 10px; }
	.table-gift colgroup { display: none; }
	
	.table-gift tr,
	.table-gift td,
	.table-gift tbody,
	.table-gift table { display: block; width: 100%; padding: 0; border: 0; }
	
	.table-gift tr { position: relative; margin-bottom: 14px; }
	.table-gift tr:first-child td { padding: 0; }
	
	.table-gift .table__icon { position: static; display: inline-block; vertical-align: top; width: 38px; height: 38px; margin-right: 8px; }
	
	.table-gift .table__title { display: inline-block; margin-top: -5px; font-size: 15px; }
	
	.table-gift .table__meta { font-size: 11px; }
	
	.table-gift .table__price-single { position: absolute; top: 7px; right: 2px; text-align: right; }
	
	.table-gift .table__price-total { position: absolute; bottom: 9px; right: 0; text-align: right; font-size: 24px; }
	.table-gift .table__foot { display: none; }
	
	.table-gift .field-number { margin-top: 13px; }
}

/* ------------------------------------------------------------ *\
	Table Order
\* ------------------------------------------------------------ */

.table-order table { margin-bottom: 0; }

.table-order th { padding: 2px 0; border-width: 1px 0; border-style: solid; border-color: #2c414e; font-family: 'Lato', sans-serif; font-size: 14px; font-weight: 400; text-align: center; }

.table-order th:first-child { text-align: left; }

.table-order th:last-child { text-align: right; }

.table-order tr { border-bottom: 1px solid #2c414e; }

.table-order tr:last-child { border-bottom: 0; }

.table-order tr:last-child td + td:before { top: 0; bottom: 16px; }

.table-order td { position: relative; padding: 12px 20px; text-align: right; }

.table-order td:first-child { padding: 12px 0; text-align: left; }

.table-order td:last-child { padding: 12px 0; }

.table-order td + td:before { content: ''; position: absolute; top: 16px; bottom: 0; left: 0; width: 1px; background: #2c414e; }

.table-order .table__cols { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; }

.table-order .table__meta { display: block; margin-bottom: 15px; font-family: 'Lato', sans-serif; font-size: 14px; font-weight: 700; line-height: 1.2; }

.table-order .table__total { padding: 5px 10px 0; font-family: 'Open Sans', sans-serif; background: #2c414e; color: #fff; }

.table-order .table__total-value { margin-bottom: 10px; font-size: 15px; font-weight: 600; text-align: right; }

.table-order .table__total-value small { display: block; font-size: 11px; }

.table-order .table__total-value span { color: #dc5d45; }

.table-order .table__total dl { line-height: 1.3; }

.table-order .table__total dt { letter-spacing: .015em; }

.table-order .table__total dd { margin-bottom: 9px; font-style: italic; }

.table-order .table__actions { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; margin-top: 4px; font-family: 'Lato', sans-serif; font-size: 14px; color: #9b9b9b; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.table-order td:nth-child(2),
	.table-order thead { display: none; }
	
	.table-order table,
	.table-order tbody,
	.table-order td,
	.table-order tr { display: block; border: 0; margin: 0; padding: 0; }
	.table-order tr { padding: 8px 0 0; }
	
	.table-order td:last-child,
	.table-order td:first-child { padding: 0; }
	
	.table-order td:before { display: none; }
	
	.table-order .table__meta { margin: 7px 0 6px; font-size: 10px; }
	
	.table-order .table__total { padding: 9px 11px; border-radius: 4px; margin-bottom: 19px; text-align: right; }
	.table-order .table__total dl { margin-top: 15px; font-size: 11px; }
	.table-order .table__total dd { margin-bottom: 5px; }
	.table-order .table__total-value { margin-bottom: 6px; }
	.table-order .table__total-value small { font-size: 10px; }
	
	.table-order .table__cols { -webkit-box-orient: vertical; -webkit-box-direction: reverse; -ms-flex-flow: column-reverse wrap; flex-flow: column-reverse wrap; }
	
	.table-order .table__actions a { display: inline-block; width: 50%; margin-bottom: 5px; }
}

/* ------------------------------------------------------------ *\
	Tags
\* ------------------------------------------------------------ */

.tags { margin: 0 -4px; font-size: 0; }

/* ------------------------------------------------------------ *\
	Tag
\* ------------------------------------------------------------ */

.tag { display: inline-block; vertical-align: middle; padding: 0 4px 7px; line-height: 1; }

.tag .tag__inner { display: inline-block; vertical-align: middle; min-width: 24px; padding: 2px 5px; border: 1px solid #979797; font-family: 'Lato', sans-serif; font-size: 15px; text-decoration: none; text-align: center; }

.tag .tag__title { display: inline-block; vertical-align: middle; }

.tag .tag__icon { position: relative; z-index: 1; display: inline-block; vertical-align: middle; font-size: 0; line-height: 1; }

.tag .tag__icon path,
.tag .tag__icon i { -webkit-transition: .3s; -o-transition: .3s; transition: .3s; }

.tag .tag__icon i + i { position: absolute; top: 0; left: 0; opacity: 0; visibility: hidden; line-height: 1; }

/*  Tag Hover  */

.tag .tag__inner:hover { background: #7eb620; border-color: #7eb620; color: #fff; }

.tag .tag__inner:hover .tag__icon path { fill: #fff; }

.tag .tag__inner:hover .tag__icon i { opacity: 0; visibility: hidden; }

.tag .tag__inner:hover .tag__icon i + i { opacity: 1; visibility: visible; }

/* ------------------------------------------------------------ *\
	Testimonials
\* ------------------------------------------------------------ */

.testimonials .testimonials__head { max-width: 442px; margin-bottom: 27px; }

.testimonials .testimonials__head .rating { margin-bottom: 10px; }

.testimonials .testimonials__title { margin-bottom: 10px; }

.testimonials .testimonials__body { position: relative; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; padding: 0 20px; }

.testimonials .testimonials__actions { position: absolute; top: 30%; left: 0; width: 100%; }

.testimonials .testimonials__arrow { position: absolute; top: 0; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); border: 0; border-radius: 0; background: transparent; }

.testimonials .testimonials__arrow--prev { left: 0; }

.testimonials .testimonials__arrow--next { right: 0; }

.testimonials .testimonials__arrow i { width: 14px; height: 18px; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.testimonials .testimonials__head { margin-bottom: 21px; }
	.testimonials .testimonials__head .rating li + li { margin-left: 4px; }
	.testimonials .testimonials__head .rating svg { width: 9px; height: 9px; }
	
	.testimonials .testimonials__title { margin-bottom: 7px; }
	.testimonials .testimonials__body { padding: 0 8px; }

	.testimonials .testimonials__arrow--prev { left: -15px; }
	.testimonials .testimonials__arrow--next { right: -15px; }
}

/* ------------------------------------------------------------ *\
	Testimonial
\* ------------------------------------------------------------ */

.testimonial { position: relative; width: 50%; padding: 0 23px; font-family: 'Lato', sans-serif; }

.testimonial + .testimonial:before { content: ''; position: absolute; top: 0; left: 0; width: 1px; height: 100%; background: #979797; }

.testimonial .testimonial__head { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; margin-bottom: 10px; }

.testimonial .testimonial__author { font-size: 14px; line-height: 1; }

.testimonial .testimonial__author small { display: inline-block; margin-right: 2px; font-size: 10px; font-weight: 400; }

.testimonial .testimonial__author span { display: inline-block; padding: 0 3px 2px; background: #dc5d45; border-radius: 2px; font-size: 10px; font-weight: 700; color: #fff; }

.testimonial .testimonial__entry { margin-bottom: 40px; font-size: 13px; line-height: 1.3; }

/* Tablet Portrait */

@media (max-width: 1023px) {
	.testimonial { width: 100%; padding: 0; margin-bottom: 10px; }
	.testimonial + .testimonial:before { display: none; }
	
	.testimonial .testimonial__head { display: block; margin-bottom: 9px; }
	.testimonial .testimonial__head .rating svg { width: 9px; height: 9px; }

	.testimonial .testimonial__author { margin-bottom: 12px; }
	
	.testimonial .testimonial__entry { margin-bottom: 10px; font-size: 11px; line-height: 1.2; }
}

/* ------------------------------------------------------------ *\
	Widgets
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widget { position: relative; padding: 14px 0; margin-bottom: 10px; }

.widget + .widget:before { content: ''; position: absolute; top: -4px; left: 0; width: 100%; height: 1px; background: #7eb620; }

.widget .widget__head { margin-bottom: 13px; }

.widget .widget__title { font-family: 'Lato', sans-serif; font-size: 17px; line-height: 1; }

.widget .widget__title i { margin-right: 5px; }

/* ------------------------------------------------------------ *\
	Themes
\* ------------------------------------------------------------ */

.list-checkboxes-color { display: flex; flex-wrap: wrap; }
.list-checkboxes-color li { position: relative; }
.list-checkboxes-color .checkbox label:before,
.list-checkboxes-color .checkbox label:after { display: none; }
.list-checkboxes-color .color_filter { width: 20px; height: 20px; padding: 0px !important; margin: 0 10px 0 0 !important; border-radius: 13px; border: 1px solid rgba(0,0,0,0.1); cursor: pointer; }
.list-checkboxes-color [type="checkbox"]:checked + .color_filter { box-shadow: 0 0 0 2px #2c414e; }

.loading-indicator:before {
    content: '';
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading-indicator:after {
    content: '';
    background:url(/static/images/sprite/loader.png);
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    animation: spinner .6s linear infinite;
    z-index: 1001;
}